import styled from 'styled-components';
import colors from 'common/utils/constants/colors.constants';
import font from 'common/utils/constants/font.constants';
import { Select as AntdSelect } from 'formik-antd';

export const Wrapper= styled.div`
    /* Select input box */
    & div.ant-select:not(.ant-select-customize-input) .ant-select-selector{
        height: 44px;
        padding-top:6px;
        background-color: ${colors.LIGHT_GREY};
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
        background-color: ${colors.LIGHT_GREY2}!important;
        color:${colors.DARK_GREY2}!important;
    }
    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input{
        height:42px;
    }
`;

export const Select= styled(AntdSelect)`
    /* Select drop down text */
    div.ant-select-item-option-content{
        font: ${font.TEXT_M};
        color:${colors.DARK_GREY2}!important;
    }
    /* Select input box text */
    & span.ant-select-selection-item{
        font: ${font.TEXT_M};
        color:${colors.DARK_GREY2}!important;
        
    }
`;



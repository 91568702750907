import styled from 'styled-components';
import { Checkbox as AntdCheckbox } from 'formik-antd';

export const FormRow = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: start;
  margin-top: 10px;
  margin-bottom: 10px;

`;

export const Checkbox =styled(AntdCheckbox)`
  padding-top:3px;
  padding-left:10px;
  padding-right:10px;
  margin-left:auto;
`;

import React, { Fragment } from 'react';
import { Wrapper,ProfileBtn,LogOutBtn,Ul,Li } from './styles';

// 1. View Subscribe to store
import { useSelector} from 'react-redux';
import { RootState } from 'state/reducers/index';

const SignUp:React.FC = () => {   
  const userStateRedux = useSelector((state: RootState) => state.userState);

  const user=userStateRedux.user;
  const firstName= (user?.firstName ? user?.firstName+' '  : '');
  const lastName= (user?.lastName ? user?.lastName : '');
  const displayedName= firstName+lastName;
  const displayedLogOut= (user?'Logout':'Login');

  return(
    <Fragment>
      <Wrapper>
        <Ul>
          {console.log(user)}
          {user?(<Li><ProfileBtn to="/staff/account/member" >{displayedName}</ProfileBtn></Li>):null}   
          <Li><LogOutBtn to="/staff/log-in" >{displayedLogOut}</LogOutBtn></Li>        
        </Ul>
      </Wrapper>
    </Fragment>
  );
};
 
export default SignUp;

import React from 'react';
import { Wrapper } from './styles';

const LogIn:React.FC = () => {  
  return(
    <Wrapper>
            Log In
    </Wrapper>
  );
};
export default LogIn;

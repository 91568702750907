import React from 'react';
import { Wrapper } from './styles';

const SignUp:React.FC = () => {  
  return(
    <Wrapper>
            SignUp
    </Wrapper>
  );
};
export default SignUp;

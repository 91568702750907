import styled from 'styled-components';
import { fontFamily } from 'common/utils/constants/font.constants';
import { Link as LinkReactRouter } from 'react-router-dom';

const Wrapper = styled.div`
  height: 145px;
  display: inline-flex;
  margin-left:auto;
`;
const Ul = styled.ul`
  list-style-type: none;
  display:inline-flex;
  margin: 0;
  padding: 0;
`;
const Li = styled.li`
  padding-top:50px;
  text-align: center;
  font-family: SourceSansPro;
  color: #fff;
`;

const ProfileBtn = styled(LinkReactRouter)`
  text-decoration: none;
  font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};;
  font-size: 22px;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  width: 126px;
  height: 51px;
  line-height: 51px;
  display:block;
  text-align:center;
  &:hover{
    background-color: white;
    color:black;
  }
  &:active{
    background-color:white;
    color:black;
  }
`;

const LogOutBtn = styled(LinkReactRouter)`
  text-decoration: none;
  font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};;
  font-size: 22px;
  margin-top: auto;
  margin-bottom: auto;
  width: 126px;
  height: 51px;
  line-height: 51px;
  display:block;
  text-align:center;
  background-color: white;
  color:black;
  &:hover{
    background-color: white;
    color:black;
  }
  &:active{
    background-color:white;
    color:black;
  }
`;


export { Wrapper,ProfileBtn,LogOutBtn ,Ul,Li };
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {  FormRow, FormColumn,DatePickerWrapper,PgFooterRow, FormFinalCheckError, DiscountCodeText} from './styles';
import {IBooking} from 'pages/staff-portal/booking/api/schema.interface';
import { withFormik , FormikProps} from 'formik';
import { validationSchema, IInnerFormValues } from './formik-validation';
import { FormSelect } from 'common/components/FormSelect';
import { FormSelectCountries } from 'common/components/FormSelectCountries';
import { FormInput } from 'common/components/FormInput';
import FormDropDown from 'common/components/FormDropDown';
import FormCheckBox from 'common/components/FormCheckBox';
import Text from 'common/components/Text';
import DatePicker from 'common/components/DatePicker';
import moment,{ Moment } from 'moment';
import { FormTextArea } from 'common/components/FormTextArea';
import Button from 'common/components/Button';
import { PARCEL_STATUS } from 'common/utils/constants/parcel-status.constants';
import { putBookingReceived, putBookingRejected, putBookingSave, putBookingOfd } from 'pages/staff-portal/booking/api/api';
import { AxiosResponse } from 'axios';
import { BackButton } from '../../Button';
import { mapPropToValueBookingForm, mapValueToPutBookingSave, mapValueToPutBookingReceived, mapValueToPutBookingRejected, mapValueToPutBookingOfd } from './formik-map-values-api';
import { scrollUpWindow } from 'common/utils/common.utils';
import { IPutBookingReceivedReq, IPutBookingSaveReq ,IPutBookingRejectedReq,IPutBookingOfdReq, IBookingDetailRes} from 'pages/staff-portal/booking/api/api.interface';


// The type of props MyForm receives
const innerForm= (props: IFormProps & FormikProps<IInnerFormValues>) => { 
  const { touched, errors, setFieldValue,values , booking} = props;  
  const handleDatePickerOkParcelDeliveryDate=(value:Moment)=> {
    console.log('onOk: ', value.utc().valueOf());
    setFieldValue('quotedParcelDeliveryDate',value.utc().valueOf());
  };
  const handleDatePickerOkCollectionTimeStamp=(value:Moment)=> {
    console.log('onOk: ', value.utc().valueOf());
    setFieldValue('collectionTimeStamp',value.utc().valueOf());
  };
  return(
    <form onSubmit={props.handleSubmit} id="booking-form">
      <FormDropDown title='1. Select T.GO outlet'>
        <FormRow>
          <FormColumn className="w-100percent">
            <FormSelect name="tgoOutlet" placeholder="TGO Outlet" variant='tgo-outlet'/>
          </FormColumn>
        </FormRow>
      </FormDropDown>
      <FormDropDown title='2. Sender&apos;s details'>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Sender's name</Text>
            <FormInput placeholder="Name" name='senderName' help={touched.senderName && errors.senderName ?errors.senderName:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Company</Text>
            <FormInput placeholder="If applicable" name='senderCompany' help={touched.senderCompany && errors.senderCompany ?errors.senderCompany:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Email</Text>
            <FormInput placeholder="Email" name='senderEmail' help={touched.senderEmail && errors.senderEmail ?errors.senderEmail:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Contact</Text>
            <FormInput placeholder="Contact number" name='senderContactNo' help={touched.senderContactNo && errors.senderContactNo ?errors.senderContactNo:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-100percent">
            <Text fontSize='m'>Address</Text>
            <FormInput placeholder="Address" name='senderAddress' help={touched.senderAddress && errors.senderAddress ?errors.senderAddress:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Suburb</Text>
            <FormInput placeholder="If applicable" name='senderSuburb' help={touched.senderSuburb && errors.senderSuburb ?errors.senderSuburb:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>City</Text>
            <FormInput placeholder="City" name='senderCity' help={touched.senderCity && errors.senderCity ?errors.senderCity:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>State</Text>
            <FormInput placeholder="State" name='senderState' help={touched.senderState && errors.senderState ?errors.senderState:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Postcode</Text>
            <FormInput placeholder="Postcode" name='senderPostalCode' help={touched.senderPostalCode && errors.senderPostalCode ?errors.senderPostalCode:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Country</Text>
            <FormSelectCountries name='senderCountry' variant='sender-country'></FormSelectCountries>
          </FormColumn>
        </FormRow>
      </FormDropDown>

      <FormDropDown title='3. Receiver&apos;s details'>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Receiver's name</Text>
            <FormInput placeholder="Name" name='receiverName' help={touched.receiverName && errors.receiverName ?errors.receiverName:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Company</Text>
            <FormInput placeholder="If applicable" name='receiverCompany' help={touched.receiverCompany && errors.receiverCompany ?errors.receiverCompany:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Email</Text>
            <FormInput placeholder="Email" name='receiverEmail' help={touched.receiverEmail && errors.receiverEmail ?errors.receiverEmail:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Contact</Text>
            <FormInput placeholder="Contact number" name='receiverContactNo' help={touched.receiverContactNo && errors.receiverContactNo ?errors.receiverContactNo:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-100percent">
            <Text fontSize='m'>Address</Text>
            <FormInput placeholder="Address" name='receiverAddress' help={touched.receiverAddress && errors.receiverAddress ?errors.receiverAddress:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Suburb</Text>
            <FormInput placeholder="If applicable" name='receiverSuburb' help={touched.receiverSuburb && errors.receiverSuburb ?errors.receiverSuburb:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>City</Text>
            <FormInput placeholder="City" name='receiverCity' help={touched.receiverCity && errors.receiverCity ?errors.receiverCity:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>State</Text>
            <FormInput placeholder="State" name='receiverState' help={touched.receiverState && errors.receiverState ?errors.receiverState:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Postcode</Text>
            <FormInput placeholder="Postcode" name='receiverPostalCode' help={touched.receiverPostalCode && errors.receiverPostalCode ?errors.receiverPostalCode:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Country</Text>
            <FormSelectCountries name='receiverCountry' variant='receiver-country'></FormSelectCountries>
          </FormColumn>
        </FormRow>
      </FormDropDown>
      <FormDropDown title='4. Parcel details'>
        <FormRow>
          <FormColumn className="w-100percent">
            <Text fontSize='m'>Parcel contents</Text>
            <FormInput placeholder="Contents" name='quotedParcelContent' help={touched.quotedParcelContent && errors.quotedParcelContent ?errors.quotedParcelContent:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Courier</Text>
            <FormInput placeholder="courier" name='quotedCourierCourier'></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Service</Text>
            <FormInput placeholder="courier" name='quotedCourierService'></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Parcel type</Text>
            <FormSelect variant='packaging' name='quotedParcelPackaging'></FormSelect>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Weight (kg)</Text>
            <FormInput placeholder="Weight" name='quotedParcelWeight'></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Dimension (cm)</Text>
            <FormInput placeholder="Optional" name='quotedParcelDimension' help={touched.quotedParcelDimension && errors.quotedParcelDimension ?errors.quotedParcelDimension:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Parcel value (SGD)</Text>
            <FormInput variant='number' placeholder="value" name='quotedParcelValue' help={touched.quotedParcelValue && errors.quotedParcelValue ?errors.quotedParcelValue:''}></FormInput>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn className="w-66percent">
            <Text fontSize='m'> Drop-off date & time</Text>
            <DatePickerWrapper>
              <DatePicker name='quotedParcelDeliveryDate' value={moment(values.quotedParcelDeliveryDate,'x')} allowClear={false} format="DD-MMM-YYYY HH:mm" showTime={{ format: 'HH:mm' }} onOk={handleDatePickerOkParcelDeliveryDate} />
            </DatePickerWrapper>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Estimated price (SGD)</Text>
            <FormInput placeholder="Quote" name='quotedCourierPrice'></FormInput>
            <DiscountCodeText> {booking.discountCode===''?'No Discount Code':'Discount : '+booking.discountCode}</DiscountCodeText>
          </FormColumn>
        </FormRow>
      </FormDropDown>

      <FormDropDown title='5. Final check (Mandatory)'>
        <FormRow>
          <FormColumn className="w-50percent">
            <FormCheckBox name='finalCheckNoDangerousGood' labelName='a. No Dangerous Goods in the parcel'></FormCheckBox>
            <FormCheckBox name='finalCheckParcelWeight' labelName='b. Parcel weight within acceptable range'></FormCheckBox>
            <FormCheckBox name='finalCheckParcelSize' labelName='c. Parcel size within acceptable range'></FormCheckBox>
          </FormColumn>
          <FormColumn className="w-50percent">
            <FormCheckBox name='finalCheckFragileStickers' labelName='d. Fragile stickers are pasted if needed'></FormCheckBox>
            <FormCheckBox name='finalCheckSenderReceiverDetails' labelName='e. Sender’s and Receiver’s details are correct'></FormCheckBox>
            <FormCheckBox name='finalCheckParcelDetails' labelName='f.  Parcel’s details are correct'></FormCheckBox>
          </FormColumn>
        </FormRow>
        <FormFinalCheckError>
          {(errors.finalCheckNoDangerousGood||errors.finalCheckParcelWeight||errors.finalCheckParcelSize||errors.finalCheckFragileStickers||errors.finalCheckSenderReceiverDetails||errors.finalCheckParcelDetails)?'*All checks required to "receive" parcel':''}
        </FormFinalCheckError>
        <FormTextArea name='finalCheckRemarks' label='g. Remarks'></FormTextArea>
      </FormDropDown>

      <FormDropDown title='6. Update status'>
        <FormRow>
          <FormColumn className="w-50percent">
            {props.booking.parcelStatus===PARCEL_STATUS.Pending?<FormSelect name='parcelStatus' variant='parcel-status-pending'></FormSelect>:null}
            {props.booking.parcelStatus===PARCEL_STATUS.Received?<FormSelect name='parcelStatus' variant='parcel-status-received'></FormSelect>:null}
          </FormColumn>
          <FormColumn className="w-50percent">
            <FormInput placeholder="Awb ref" name='awbRef' help={touched.awbRef && errors.awbRef ?errors.awbRef:''}></FormInput>
          </FormColumn>
        </FormRow>
        {props.booking.parcelStatus===PARCEL_STATUS.Received
          ?(<FormRow>
            <FormColumn className="w-50percent">
              <DatePickerWrapper>
                <DatePicker name='collectionTimeStamp' defaultValue={moment(values.collectionTimeStamp, 'x')} format="DD-MMM-YYYY HH:mm" showTime={{ format: 'HH:mm' }} allowClear={false} showNow={false} onOk={handleDatePickerOkCollectionTimeStamp} />
              </DatePickerWrapper>
            </FormColumn>
            <FormColumn className="w-50percent">
              <FormInput placeholder="Collection ref" name='collectionRef' help={touched.collectionRef && errors.collectionRef ?errors.collectionRef:''}></FormInput>
            </FormColumn>
          </FormRow>)
          :null}
      </FormDropDown>
      <PgFooterRow>
        <BackButton></BackButton>
        <Button variant='primary' form='booking-form' type="submit">Save</Button>
      </PgFooterRow>
    </form>
  );
};

interface IFormProps {
  booking: IBooking;
  setBooking:React.Dispatch<React.SetStateAction<IBooking>>,
  showModalSuccess:() => void,
  showModalError:() => void
}

export const BookingForm = withFormik<IFormProps,IInnerFormValues>({
  validationSchema:validationSchema,  
  validateOnChange:false,
  enableReinitialize: true,

  // Transform outer props into form values
  mapPropsToValues: props => {
    return mapPropToValueBookingForm(props.booking);
  },

  // Form Submission
  handleSubmit: async (values, formikBag) => {
    console.log('Submitting Booking Form',values);
    const currParcelStatus=formikBag.props.booking.parcelStatus;
    const nextParcelStatus=values.parcelStatus;
    scrollUpWindow();
    
    if( currParcelStatus===PARCEL_STATUS.Pending && nextParcelStatus===PARCEL_STATUS.Pending){
      //Save Api
      const booking:IPutBookingSaveReq=mapValueToPutBookingSave(values);
      const bookingRes:boolean | AxiosResponse<IBookingDetailRes>=await putBookingSave(booking);
      if(typeof(bookingRes)!='boolean'){
        console.log('success',bookingRes);
        formikBag.props.setBooking(bookingRes.data.parcel);
        formikBag.props.showModalSuccess();
      }else{
        console.log('error');
      }
    }
    if( currParcelStatus===PARCEL_STATUS.Pending &&  nextParcelStatus===PARCEL_STATUS.Received){
      //Received Api
      let formLevelError=false;
      if(values.finalCheckNoDangerousGood===false){formikBag.setFieldError('finalCheckNoDangerousGood','needs to be true');formLevelError=true;}
      if(values.finalCheckParcelWeight===false){formikBag.setFieldError('finalCheckParcelWeight','needs to be true');formLevelError=true;}
      if(values.finalCheckParcelSize===false){formikBag.setFieldError('finalCheckParcelSize','needs to be true');formLevelError=true;}
      if(values.finalCheckFragileStickers===false){formikBag.setFieldError('finalCheckFragileStickers','needs to be true');formLevelError=true;}
      if(values.finalCheckSenderReceiverDetails===false){formikBag.setFieldError('finalCheckSenderReceiverDetails','needs to be true');formLevelError=true;}
      if(values.finalCheckParcelDetails===false){formikBag.setFieldError('finalCheckParcelDetails','needs to be true');formLevelError=true;}
      
      if(values.awbRef===''){formikBag.setFieldError('awbRef','* Required, Key in AWB Ref to "receive"');formLevelError=true;}

      if(!formLevelError){
        const booking:IPutBookingReceivedReq=mapValueToPutBookingReceived(values);
        const bookingRes:boolean | AxiosResponse<IBookingDetailRes>=await putBookingReceived(booking);
        if(typeof(bookingRes)!='boolean'){
          console.log('success',bookingRes);
          formikBag.props.setBooking(bookingRes.data.parcel);
          formikBag.props.showModalSuccess();
        }else{
          console.log('error');
        }
      }else{
        console.log('error');
      }
    }
    if( currParcelStatus===PARCEL_STATUS.Pending && nextParcelStatus===PARCEL_STATUS.Rejected){
      //Reject Api
      const booking:IPutBookingRejectedReq=mapValueToPutBookingRejected(values);
      const bookingRes:boolean | AxiosResponse<IBookingDetailRes>=await putBookingRejected(booking);
      if(typeof(bookingRes)!='boolean'){
        console.log('success',bookingRes);
        formikBag.props.setBooking(bookingRes.data.parcel);
        formikBag.props.showModalSuccess();
      }else{
        console.log('error');
        formikBag.props.showModalError();
      }
    }
    if(currParcelStatus===PARCEL_STATUS.Received && nextParcelStatus===PARCEL_STATUS.Received){
      //Save Api
      const booking:IPutBookingSaveReq=mapValueToPutBookingSave(values);
      const bookingRes:boolean | AxiosResponse<IBookingDetailRes>=await putBookingSave(booking);
      if(typeof(bookingRes)!='boolean'){
        console.log('success',bookingRes);
        formikBag.props.setBooking(bookingRes.data.parcel);
        formikBag.props.showModalSuccess();
      }else{
        console.log('error');
        formikBag.props.showModalError();
      }
    }
    if(currParcelStatus===PARCEL_STATUS.Received && nextParcelStatus===PARCEL_STATUS.OutForDelivery){
      //OutForDelivery Api
      const booking:IPutBookingOfdReq=mapValueToPutBookingOfd(values);
      const bookingRes:boolean | AxiosResponse<IBookingDetailRes>=await putBookingOfd(booking);
      if(typeof(bookingRes)!='boolean'){
        console.log('success',bookingRes);
        formikBag.props.setBooking(bookingRes.data.parcel);
        formikBag.props.showModalSuccess();
      }else{
        console.log('error');
        formikBag.props.showModalError();
      }
    }
    if(currParcelStatus===PARCEL_STATUS.Received && nextParcelStatus===PARCEL_STATUS.Rejected){
      //Reject Api
      const booking:IPutBookingRejectedReq=mapValueToPutBookingRejected(values);
      const bookingRes:boolean | AxiosResponse<IBookingDetailRes>=await putBookingRejected(booking);
      if(typeof(bookingRes)!='boolean'){
        console.log('success',bookingRes);
        formikBag.props.setBooking(bookingRes.data.parcel);
        formikBag.props.showModalSuccess();
      }else{
        console.log('error');
        formikBag.props.showModalError();
      }
    }
  },

})(innerForm);
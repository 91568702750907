import styled from 'styled-components';
import font, { fontWeight } from 'common/utils/constants/font.constants';
import { screenSizes } from 'common/utils/constants/screen-sizes.constants';

const Wrapper = styled.div`
  max-width:1000px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;
const PgHeaderPhone= styled.div`
  margin:30px 10px 0px 10px;
  text-align:left;

  @media (min-width:${screenSizes.W768}){  
    display: none;   
  }
`;

const BookingSuccessWrapper = styled.div`
  padding:50px;
  border-radius: 10px;
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  display:flex;
  flex-direction:column;
  @media (max-width:calc(${screenSizes.W768} - 1px)){  
    margin:10px 10px 30px 10px;
  }
  @media (min-width:${screenSizes.W768}){    
    margin:60px 10px 30px 10px;
  }

`;

const BookingSuccessHeader1 = styled.div`
  font:${font.H2};
  text-align: center;
  font-weight:${fontWeight.H};
  padding-bottom: 50px;
  @media (max-width:calc(${screenSizes.W768} - 1px)){  
    display: none;   
  }
`;

const BookingSuccessHeader2 = styled.div`
  font:${font.H3};
  font-weight:${fontWeight.H};
  text-align: center;
  font-weight:bold;
  padding-bottom: 10px;
`;

const BookingSuccessText = styled.div`
  margin-top: 70px;
  line-height: 30px;
  font:${font.TEXT_M};
  text-align: center;
`;
export { Wrapper, PgHeaderPhone, BookingSuccessWrapper,BookingSuccessHeader1,BookingSuccessHeader2, BookingSuccessText };
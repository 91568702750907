/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { FormRow, FormColumn,DatePickerWrapper, PgFooterRow, DiscountCodeText} from './styles';
import {IBooking} from 'pages/staff-portal/booking/api/schema.interface';
import { withFormik , FormikProps} from 'formik';
import { IInnerFormValues } from './formik-validation';
import { FormSelect } from 'common/components/FormSelect';
import { FormInput } from 'common/components/FormInput';
import FormDropDown from 'common/components/FormDropDown';
import FormCheckBox from 'common/components/FormCheckBox';
import Text from 'common/components/Text';
import DatePicker from 'common/components/DatePicker';
import moment from 'moment';
import { FormTextArea } from 'common/components/FormTextArea';
import { mapPropToValueBookingFormReadOnly } from './formik-map-values-api';
import { BackButton } from 'pages/staff-portal/booking/BookingDetail/components/Button';
import { FormSelectCountries } from 'common/components/FormSelectCountries';

// The type of props MyForm receives
const innerForm= (props: IFormProps & FormikProps<IInnerFormValues>) => { 
  const { touched, errors,values,booking } = props;  
  return(
    <form onSubmit={props.handleSubmit} id="booking-form">
      <FormDropDown title='1. Select T.GO outlet'>
        <FormRow>
          <FormColumn className="w-100percent">
            <FormSelect disabled={true} name="tgoOutlet" placeholder="TGO Outlet" variant='tgo-outlet'/>
          </FormColumn>
        </FormRow>
      </FormDropDown>
      <FormDropDown title='2. Sender&apos;s details'>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Sender's name</Text>
            <FormInput disabled={true} placeholder="Name" name='senderName' help={touched.senderName && errors.senderName ?errors.senderName:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Company</Text>
            <FormInput disabled={true} placeholder="If applicable" name='senderCompany' help={touched.senderCompany && errors.senderCompany ?errors.senderCompany:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Email</Text>
            <FormInput disabled={true} placeholder="Email" name='senderEmail' help={touched.senderEmail && errors.senderEmail ?errors.senderEmail:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Contact</Text>
            <FormInput disabled={true} placeholder="Contact number" name='senderContactNo' help={touched.senderContactNo && errors.senderContactNo ?errors.senderContactNo:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-100percent">
            <Text fontSize='m'>Address</Text>
            <FormInput disabled={true} placeholder="Address" name='senderAddress' help={touched.senderAddress && errors.senderAddress ?errors.senderAddress:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Suburb</Text>
            <FormInput disabled={true} placeholder="If applicable" name='senderSuburb' help={touched.senderSuburb && errors.senderSuburb ?errors.senderSuburb:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>City</Text>
            <FormInput disabled={true} placeholder="City" name='senderCity' help={touched.senderCity && errors.senderCity ?errors.senderCity:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>State</Text>
            <FormInput disabled={true} placeholder="State" name='senderState' help={touched.senderState && errors.senderState ?errors.senderState:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Postcode</Text>
            <FormInput disabled={true} placeholder="Postcode" name='senderPostalCode' help={touched.senderPostalCode && errors.senderPostalCode ?errors.senderPostalCode:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Country</Text>
            <FormSelectCountries disabled={true} name='senderCountry' variant='sender-country'></FormSelectCountries>
          </FormColumn>
        </FormRow>
      </FormDropDown>

      <FormDropDown title='3. Receiver&apos;s details'>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Receiver's name</Text>
            <FormInput disabled={true} placeholder="Name" name='receiverName' help={touched.receiverName && errors.receiverName ?errors.receiverName:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Company</Text>
            <FormInput disabled={true} placeholder="If applicable" name='receiverCompany' help={touched.receiverCompany && errors.receiverCompany ?errors.receiverCompany:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Email</Text>
            <FormInput disabled={true} placeholder="Email" name='receiverEmail' help={touched.receiverEmail && errors.receiverEmail ?errors.receiverEmail:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Contact</Text>
            <FormInput disabled={true} placeholder="Contact number" name='receiverContactNo' help={touched.receiverContactNo && errors.receiverContactNo ?errors.receiverContactNo:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-100percent">
            <Text fontSize='m'>Address</Text>
            <FormInput disabled={true} placeholder="Address" name='receiverAddress' help={touched.receiverAddress && errors.receiverAddress ?errors.receiverAddress:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Suburb</Text>
            <FormInput disabled={true} placeholder="If applicable" name='receiverSuburb' help={touched.receiverSuburb && errors.receiverSuburb ?errors.receiverSuburb:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>City</Text>
            <FormInput disabled={true} placeholder="City" name='receiverCity' help={touched.receiverCity && errors.receiverCity ?errors.receiverCity:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>State</Text>
            <FormInput disabled={true} placeholder="State" name='receiverState' help={touched.receiverState && errors.receiverState ?errors.receiverState:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Postcode</Text>
            <FormInput disabled={true} placeholder="Postcode" name='receiverPostalCode' help={touched.receiverPostalCode && errors.receiverPostalCode ?errors.receiverPostalCode:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Country</Text>
            <FormSelectCountries disabled={true} name='receiverCountry' variant='receiver-country'></FormSelectCountries>
          </FormColumn>
        </FormRow>
      </FormDropDown>
      <FormDropDown title='4. Parcel details'>
        <FormRow>
          <FormColumn className="w-100percent">
            <Text fontSize='m'>Parcel contents</Text>
            <FormInput disabled={true} placeholder="Contents" name='quotedParcelContent' help={touched.quotedParcelContent && errors.quotedParcelContent ?errors.quotedParcelContent:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Courier</Text>
            <FormInput disabled={true} placeholder="courier" name='quotedCourierCourier'></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Service</Text>
            <FormInput disabled={true} placeholder="courier" name='quotedCourierService'></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Parcel type</Text>
            <FormSelect disabled={true} variant='packaging' name='quotedParcelPackaging'></FormSelect>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Weight (kg)</Text>
            <FormInput disabled={true} placeholder="Weight" name='quotedParcelWeight'></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Dimension (cm)</Text>
            <FormInput disabled={true} placeholder="Optional" name='quotedParcelDimension' help={touched.quotedParcelDimension && errors.quotedParcelDimension ?errors.quotedParcelDimension:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Parcel value (SGD)</Text>
            <FormInput disabled={true} variant='number' placeholder="value" name='quotedParcelValue' help={touched.quotedParcelValue && errors.quotedParcelValue ?errors.quotedParcelValue:''}></FormInput>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn className="w-66percent">
            <Text fontSize='m'> Drop-off date & time</Text>
            <DatePickerWrapper>
              <DatePicker disabled={true} name='quotedParcelDeliveryDate' value={moment(values.quotedParcelDeliveryDate,'x')} format="DD-MMM-YYYY HH:mm" showTime={{ format: 'HH:mm' }}/>
            </DatePickerWrapper>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Estimated price (SGD)</Text>
            <FormInput disabled={true} placeholder="Quote" name='quotedCourierPrice'></FormInput>
            <DiscountCodeText> {booking.discountCode===''?'No Discount Code':'Discount : '+booking.discountCode}</DiscountCodeText>
          </FormColumn>
        </FormRow>
      </FormDropDown>

      <FormDropDown title='5. Final check (Mandatory)'>
        <FormRow>
          <FormColumn className="w-50percent">
            <FormCheckBox disabled={true} name='finalCheckNoDangerousGood' labelName='a. No Dangerous Goods in the parcel'></FormCheckBox>
            <FormCheckBox disabled={true} name='finalCheckParcelWeight' labelName='b. Parcel weight within acceptable range'></FormCheckBox>
            <FormCheckBox disabled={true} name='finalCheckParcelSize' labelName='c. Parcel size within acceptable range'></FormCheckBox>
          </FormColumn>
          <FormColumn className="w-50percent">
            <FormCheckBox disabled={true} name='finalCheckFragileStickers' labelName='d. Fragile stickers are pasted if needed'></FormCheckBox>
            <FormCheckBox disabled={true} name='finalCheckSenderReceiverDetails' labelName='e. Sender’s and Receiver’s details are correct'></FormCheckBox>
            <FormCheckBox disabled={true} name='finalCheckParcelDetails' labelName='f.  Parcel’s details are correct'></FormCheckBox>
          </FormColumn>
        </FormRow>
        <FormTextArea disabled={true} name='finalCheckRemarks' label='g. Remarks'></FormTextArea>
      </FormDropDown>

      <FormDropDown title='6. Update status'>
        <FormRow>
          <FormColumn className="w-50percent">
            <FormSelect disabled={true} name='parcelStatus' variant='parcel-status'></FormSelect>
          </FormColumn>
          <FormColumn className="w-50percent">
            <FormInput disabled={true} placeholder="Awb ref" name='awbRef' help={touched.awbRef && errors.awbRef ?errors.awbRef:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-50percent">
            {(values.collectionTimeStamp===-1?
              null
              : 
              <DatePickerWrapper>
                <DatePicker disabled={true} name='collectionTimeStamp' defaultValue={moment(values.collectionTimeStamp, 'x')} format="DD-MMM-YYYY HH:mm" showTime={{ format: 'HH:mm' }} />
              </DatePickerWrapper>
            )}
          </FormColumn>
          <FormColumn className="w-50percent">
            {(values.collectionTimeStamp===-1?
              null
              : 
              <FormInput disabled={true} placeholder="Collection ref" name='collectionRef' help={touched.collectionRef && errors.collectionRef ?errors.collectionRef:''}></FormInput>
            )}
          </FormColumn>
        </FormRow>
      </FormDropDown>
      <PgFooterRow>
        <BackButton></BackButton>
      </PgFooterRow>
    </form>
  );
};

interface IFormProps {
  booking: IBooking;
  setBooking:React.Dispatch<React.SetStateAction<IBooking>>,
}

export const BookingFormReadOnly = withFormik<IFormProps,IInnerFormValues>({
  // Transform outer props into form values
  mapPropsToValues: props => {
    return mapPropToValueBookingFormReadOnly(props.booking);
  },

  // Form Submission
  handleSubmit: async (values) => {
    console.log(values);
  },

})(innerForm);
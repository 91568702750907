import styled from 'styled-components';
import colors from 'common/utils/constants/colors.constants';
import font, {fontFamily} from 'common/utils/constants/font.constants';
import { screenSizes } from 'common/utils/constants/screen-sizes.constants';

const Wrapper = styled.div`
`;

const Pg5Wrapper = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    padding:50px;
    min-height: 425px;
    border-radius: 10px;
    box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    display:flex;
    flex-direction:column;
`;

const Pg5Header1 = styled.div`
    height: 50px;
    width:100%;
    font:${font.H4};
    text-align: left;
    font-weight:bold;
    padding-bottom: 1px;
    margin:20px 20px;
    border-bottom: solid 1px ${colors.LIGHT_GREY2};
    @media (max-width:calc(${screenSizes.W600} - 1px)){ 
        margin:20px 0px;
    }
`;

const Pg5Header2 = styled.div`
    height: 30px;
    font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};;
    font:${font.H5};
    text-align: left;
    font-weight:bold;
    padding-bottom: 10px;
    margin-top:20px;
    margin-bottom: 20px;
    border-bottom: solid 1px ${colors.LIGHT_GREY2};
`;


const Pg5Row = styled.div`
    @media (min-width:calc(${screenSizes.W600})){ 
        display:flex;
        flex-direction:row;
        &.w-33percent{
            width:33%
        }
        &.w-50percent{
            width:50%
        }
        &.w-66percent{
            width:66%
        }
    }
    @media (max-width:calc(${screenSizes.W600} - 1px)){ 
        display:flex;
        flex-direction:column;
    }
`;

const Pg5Column = styled.div`
    @media (min-width:calc(${screenSizes.W600})){ 
        display:flex;
        flex-direction:column;
        margin:0px 10px;
        &.w-33percent{
            width:33%
        }
        &.w-50percent{
            width:50%
        }
        &.w-66percent{
            width:66%
        }
    }
    @media (max-width:calc(${screenSizes.W600} - 1px)){ 
        width:100%;
    }
`;

const Pg5Text = styled.div`
    font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};;
    font-size: 14px;
    text-align: left;
`;

const PgFooterRow = styled.div`
    display:flex;
    flex-direction:row;
    @media (max-width:calc(${screenSizes.W480} - 1px)){     
        justify-content: center;  
    }
    @media (min-width:${screenSizes.W480}){  
        justify-content: right;
    }
`;

export { Wrapper, Pg5Wrapper, Pg5Column, Pg5Row, Pg5Header1,Pg5Header2,Pg5Text, PgFooterRow };
import styled from 'styled-components';
import { screenSizes } from 'common/utils/constants/screen-sizes.constants';

const Wrapper = styled.div`
    margin-left:auto;
    margin-right:auto;
    margin-bottom:10px;
    @media (max-width:${screenSizes.W768}){
        width: 100%;
    }

    @media (max-width:${screenSizes.W1024}) and (min-width:${screenSizes.W768}){
        width: 80%;
    }
    @media (min-width:${screenSizes.W1024}){
        width: 80%;
        max-width: 1200px;
    }
`;

const Header1 = styled.div`
    font-family: HelveticaNeue;
    font-size: 48px;
    font-weight: normal;
    margin-bottom: 10px;
    color: #000;
`;

const PgBlackBtn = styled.div`
    width: 137px;
    height: 51px;
    margin-right: 5px;
    padding: 11px 36px 8px 37px;
    border-radius: 8px;
    background-color: white;
    font-size: 22px;
    font-weight: normal;
    color:black;
    border-radius: 8px;
    border: solid 1px #000;
    &.active{
        background-color: #000;
        color:white
    }
`;

const PgWhiteBtn = styled.div`
    width: 137px;
    height: 51px;
    margin-right: 5px;
    padding: 11px 36px 8px 37px;
    border-radius: 8px;
    background-color: white;
    font-size: 22px;
    font-weight: normal;
    color:black;
    border-radius: 8px;
    border: solid 1px #000;
    &.active{
        background-color: #000;
        color:white;
    }
    
`;

const PgHeaderTab =styled.div`
    display:flex;
    flex-direction:row;
    margin-bottom: 30px;
`;

export { Wrapper, Header1, PgBlackBtn, PgWhiteBtn, PgHeaderTab};
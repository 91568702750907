import React from 'react';
import { Wrapper } from './styles';

const MemberProfile:React.FC = () => {  
  return(
    <Wrapper>
            Member Profile & Details
    </Wrapper>
  );
};
export default MemberProfile;

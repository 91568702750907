import { IInnerFormValues } from './formik-validation';
import { IPutBookingSaveReq,IPutBookingReceivedReq,IPutBookingRejectedReq,IPutBookingOfdReq } from 'pages/staff-portal/booking/api/api.interface';
import { PARCEL_STATUS } from 'common/utils/constants/parcel-status.constants';
import { IBooking } from 'pages/staff-portal/booking/api/schema.interface';
import { getCurrentTimeUnix } from 'common/utils/common.utils';

export const mapPropToValueBookingForm=(booking:IBooking)=>{
  return {
    tgoOutlet: booking.tgoOutlet||'',
    senderName: booking.sender.name||'',
    senderCompany: booking.sender.company||'',
    senderEmail: booking.sender.email||'',
    senderContactNo: booking.sender.contactNo||'',
    senderAddress: booking.sender.address||'',
    senderSuburb: booking.sender.suburb||'',
    senderCity: booking.sender.city||'',
    senderState: booking.sender.state||'',
    senderPostalCode: booking.sender.postalCode||'',
    senderCountry: booking.sender.country||'',

    receiverName: booking.receiver.name||'',
    receiverCompany: booking.receiver.company||'',
    receiverEmail: booking.receiver.email||'',
    receiverContactNo: booking.receiver.contactNo||'',
    receiverAddress: booking.receiver.address||'',
    receiverSuburb: booking.receiver.suburb||'',
    receiverCity: booking.receiver.city||'',
    receiverState: booking.receiver.state||'',
    receiverPostalCode: booking.receiver.postalCode||'',
    receiverCountry: booking.receiver.country||'',

    quotedParcelContent: booking.quotedParcel.content||'',
    quotedParcelDeliveryDate:booking.quotedParcel.deliveryDate,
    quotedParcelValue:booking.quotedParcel.value||0,
    quotedParcelPackaging:booking.quotedParcel.packaging||'',
    quotedParcelDimension:booking.quotedParcel.dimension||'',
    quotedParcelWeight:booking.quotedParcel.weight||0,

    quotedCourierCourier: booking.quotedCourier.courier||'',
    quotedCourierEta: booking.quotedCourier.eta||'',
    quotedCourierPrice: booking.quotedCourier.price||0,
    quotedCourierService: booking.quotedCourier.service||'',
    quotedCourierServiceInfo: booking.quotedCourier.serviceInfo||'',

    finalCheckNoDangerousGood:booking.finalCheck.noDangerousGood||false,
    finalCheckParcelWeight:booking.finalCheck.parcelWeight||false,
    finalCheckParcelSize:booking.finalCheck.parcelSize||false,
    finalCheckFragileStickers:booking.finalCheck.fragileStickers||false,
    finalCheckSenderReceiverDetails:booking.finalCheck.senderReceiverDetails||false,
    finalCheckParcelDetails:booking.finalCheck.parcelDetails||false,
    finalCheckRemarks:booking.finalCheck.remarks||'',

    prevParcelStatus:booking.parcelStatus||'',
    parcelStatus:booking.parcelStatus||'',
    awbRef:booking.awbRef||'',
    collectionTimeStamp:booking.collectionTimeStamp||getCurrentTimeUnix(),
    collectionRef:booking.collectionRef||'',
    bookingRef:booking.bookingRef||''
  };
};

export const mapValueToPutBookingSave=(values:IInnerFormValues):IPutBookingSaveReq=>{
  let collectionTimeStamp=undefined;
  let collectionRef=undefined;
  if (values.parcelStatus===PARCEL_STATUS.Pending){
    collectionTimeStamp=undefined;
    collectionRef=undefined;
  }
  if (values.parcelStatus===PARCEL_STATUS.Received){
    collectionTimeStamp=values.collectionTimeStamp;
    collectionRef=values.collectionRef;
  }

  const putBookingReq:IPutBookingSaveReq={
    'bookingRef':values.bookingRef,
    'tgoOutlet': values.tgoOutlet,
    'awbRef': values.awbRef,
    'collectionTimeStamp':collectionTimeStamp,
    'collectionRef':collectionRef,
    'sender':{
      'name':values.senderName,
      'email':values.senderEmail,
      'company': values.senderCompany,
      'contactNo':values.senderContactNo,
      'country':values.senderCountry,
      'address':values.senderAddress,
      'suburb': values.senderSuburb,
      'city': values.senderCity,
      'state': values.senderState,
      'postalCode':values.senderPostalCode
    },
    'receiver':{
      'name':values.receiverName,
      'email':values.receiverEmail,
      'contactNo':values.receiverContactNo,
      'company': values.receiverCompany,
      'country':values.receiverCountry,
      'address':values.receiverAddress,
      'suburb': values.receiverSuburb,
      'city': values.receiverCity,
      'state': values.receiverState,
      'postalCode':values.receiverPostalCode
    },
    'finalCheck':{
      'noDangerousGood':values.finalCheckNoDangerousGood,
      'parcelWeight':values.finalCheckParcelWeight,
      'parcelSize':values.finalCheckParcelSize,
      'fragileStickers':values.finalCheckFragileStickers,
      'senderReceiverDetails':values.finalCheckSenderReceiverDetails,
      'parcelDetails':values.finalCheckParcelDetails,
      'remarks':values.finalCheckRemarks
    } ,
    'quotedParcel':{
      'content':values.quotedParcelContent,
      'value':values.quotedParcelValue,
      'packaging':values.quotedParcelPackaging,
      'deliveryDate':values.quotedParcelDeliveryDate,
      'dimension':values.quotedParcelDimension,
      'weight':values.quotedParcelWeight
    },
    'quotedCourier':{
      'price':values.quotedCourierPrice,
      'service':values.quotedCourierService, 
      'courier':values.quotedCourierCourier,
    }
  };
  return putBookingReq;
};

export const mapValueToPutBookingReceived=(values:IInnerFormValues):IPutBookingReceivedReq=>{
  const putBookingReq:IPutBookingReceivedReq={
    'bookingRef':values.bookingRef,
    'tgoOutlet': values.tgoOutlet,
    'awbRef': values.awbRef,
    'sender':{
      'name':values.senderName,
      'email':values.senderEmail,
      'company': values.senderCompany,
      'contactNo':values.senderContactNo,
      'country':values.senderCountry,
      'address':values.senderAddress,
      'suburb': values.senderSuburb,
      'city': values.senderCity,
      'state': values.senderState,
      'postalCode':values.senderPostalCode
    },
    'receiver':{
      'name':values.receiverName,
      'email':values.receiverEmail,
      'contactNo':values.receiverContactNo,
      'company': values.receiverCompany,
      'country':values.receiverCountry,
      'address':values.receiverAddress,
      'suburb': values.receiverSuburb,
      'city': values.receiverCity,
      'state': values.receiverState,
      'postalCode':values.receiverPostalCode
    },
    'finalCheck':{
      'remarks':values.finalCheckRemarks
    } ,
    'quotedParcel':{
      'content':values.quotedParcelContent,
      'value':values.quotedParcelValue,
      'packaging':values.quotedParcelPackaging,
      'deliveryDate':values.quotedParcelDeliveryDate,
      'dimension':values.quotedParcelDimension,
      'weight':values.quotedParcelWeight
    },
    'quotedCourier':{
      'price':values.quotedCourierPrice,
      'service':values.quotedCourierService, 
      'courier':values.quotedCourierCourier,
    }
  };
  return putBookingReq;
};


export const mapValueToPutBookingRejected=(values:IInnerFormValues):IPutBookingRejectedReq=>{
  let collectionTimeStamp=undefined;
  let collectionRef=undefined;
  if (values.parcelStatus===PARCEL_STATUS.Pending){
    collectionTimeStamp=undefined;
    collectionRef=undefined;
  }
  if (values.parcelStatus===PARCEL_STATUS.Received){
    collectionTimeStamp=values.collectionTimeStamp;
    collectionRef=values.collectionRef;
  }

  const putBookingReq:IPutBookingRejectedReq={
    'bookingRef':values.bookingRef,
    'tgoOutlet': values.tgoOutlet,
    'awbRef': values.awbRef,
    'collectionTimeStamp':collectionTimeStamp,
    'collectionRef':collectionRef,
    'sender':{
      'name':values.senderName,
      'email':values.senderEmail,
      'company': values.senderCompany,
      'contactNo':values.senderContactNo,
      'country':values.senderCountry,
      'address':values.senderAddress,
      'suburb': values.senderSuburb,
      'city': values.senderCity,
      'state': values.senderState,
      'postalCode':values.senderPostalCode
    },
    'receiver':{
      'name':values.receiverName,
      'email':values.receiverEmail,
      'contactNo':values.receiverContactNo,
      'company': values.receiverCompany,
      'country':values.receiverCountry,
      'address':values.receiverAddress,
      'suburb': values.receiverSuburb,
      'city': values.receiverCity,
      'state': values.receiverState,
      'postalCode':values.receiverPostalCode
    },
    'finalCheck':{
      'noDangerousGood':values.finalCheckNoDangerousGood,
      'parcelWeight':values.finalCheckParcelWeight,
      'parcelSize':values.finalCheckParcelSize,
      'fragileStickers':values.finalCheckFragileStickers,
      'senderReceiverDetails':values.finalCheckSenderReceiverDetails,
      'parcelDetails':values.finalCheckParcelDetails,
      'remarks':values.finalCheckRemarks
    } ,
    'quotedParcel':{
      'content':values.quotedParcelContent,
      'value':values.quotedParcelValue,
      'packaging':values.quotedParcelPackaging,
      'deliveryDate':values.quotedParcelDeliveryDate,
      'dimension':values.quotedParcelDimension,
      'weight':values.quotedParcelWeight
    },
    'quotedCourier':{
      'price':values.quotedCourierPrice,
      'service':values.quotedCourierService, 
      'courier':values.quotedCourierCourier,
    }
  };
  return putBookingReq;
};

export const mapValueToPutBookingOfd=(values:IInnerFormValues):IPutBookingOfdReq=>{
  const putBookingReq:IPutBookingOfdReq={
    'bookingRef':values.bookingRef,
    'tgoOutlet': values.tgoOutlet,
    'awbRef': values.awbRef,
    'collectionRef':values.collectionRef,
    'collectionTimeStamp':values.collectionTimeStamp,
    'sender':{
      'name':values.senderName,
      'email':values.senderEmail,
      'company': values.senderCompany,
      'contactNo':values.senderContactNo,
      'country':values.senderCountry,
      'address':values.senderAddress,
      'suburb': values.senderSuburb,
      'city': values.senderCity,
      'state': values.senderState,
      'postalCode':values.senderPostalCode
    },
    'receiver':{
      'name':values.receiverName,
      'email':values.receiverEmail,
      'contactNo':values.receiverContactNo,
      'company': values.receiverCompany,
      'country':values.receiverCountry,
      'address':values.receiverAddress,
      'suburb': values.receiverSuburb,
      'city': values.receiverCity,
      'state': values.receiverState,
      'postalCode':values.receiverPostalCode
    },
    'finalCheck':{
      'remarks':values.finalCheckRemarks
    } ,
    'quotedParcel':{
      'content':values.quotedParcelContent,
      'value':values.quotedParcelValue,
      'packaging':values.quotedParcelPackaging,
      'deliveryDate':values.quotedParcelDeliveryDate,
      'dimension':values.quotedParcelDimension,
      'weight':values.quotedParcelWeight
    },
    'quotedCourier':{
      'price':values.quotedCourierPrice,
      'service':values.quotedCourierService, 
      'courier':values.quotedCourierCourier,
    }
  };
  return putBookingReq;
};
import React, { Fragment } from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {Header as StaffHeader} from 'common/components/HeaderStaff';
import Dashboard from 'pages/staff-portal/dashboard/DashBoard';
import BookingAll from 'pages/staff-portal/booking/BookingAll';
import BookingDetail from 'pages/staff-portal/booking/BookingDetail';
import {LogIn as StaffLogin} from 'pages/staff-portal/authentication/LogIn';
import { ForgotPassword as StaffForgotPassword } from 'pages/staff-portal/authentication/ForgotPassword';
import AccountManagement from 'pages/staff-portal/account/AccountManagement';
import AddAccount from 'pages/staff-portal/account/AddAccount';
import { Member as StaffMember } from 'pages/staff-portal/account/Member';
import ProtectedRoute from './ProtectedRoute';
import { isAuthenticated } from 'common/utils/auth.utils';

const RouterStaff:React.FC = () => {
  const authenticationPath='/staff/log-in';
  return (
    <Fragment>
      {/* Staff Portal */}
      <header>
        <StaffHeader></StaffHeader>
      </header>
      <main>
        <Switch>
          <Route path="/staff/log-in" exact>
            <StaffLogin></StaffLogin>
          </Route>
          <Route path="/staff/forgot-password" exact>
            <StaffForgotPassword></StaffForgotPassword>
          </Route>
          <ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated()} path="/staff/dashboard" exact>
            {/* Redirect all path to parcel-booking */}
            <Redirect to="/staff/booking"></Redirect>
            <Dashboard></Dashboard>
          </ProtectedRoute> 
          <ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated()} path="/staff/booking" exact>
            <BookingAll></BookingAll>
          </ProtectedRoute>
          <ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated()} path="/staff/booking/:bookingRef" exact>
            <BookingDetail></BookingDetail>
          </ProtectedRoute> 
          <ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated()} path="/staff/account/account-management" exact>
            {/* Redirect all path to parcel-booking */}
            <Redirect to="/staff/booking"></Redirect>
            <AccountManagement></AccountManagement>
          </ProtectedRoute> 
          <ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated()} path="/staff/account/add-account" exact>
            <AddAccount></AddAccount>
          </ProtectedRoute> 
          <ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated()} path="/staff/account/member" exact>
            {/* Redirect all path to parcel-booking */}
            <Redirect to="/staff/booking"></Redirect>
            <StaffMember></StaffMember>
          </ProtectedRoute> 
          <ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated()} path="/staff">
            {/* Redirect all path to parcel-booking */}
            <Redirect to="/staff/booking"></Redirect>
          </ProtectedRoute> 
        </Switch>
      </main>
    </Fragment>
  );
};
export default RouterStaff;

import styled from 'styled-components';
import { screenSizes } from 'common/utils/constants/screen-sizes.constants';

export const Wrapper = styled.div`
    max-width: 1000px;
    margin-left:auto;
    margin-right:auto;
`;
export const PgFooterRow = styled.div`
    display:flex;
    flex-direction:row;
    @media (max-width:calc(${screenSizes.W480} - 1px)){     
        justify-content: center;  
    }
    @media (min-width:${screenSizes.W480}){  
        justify-content: right;
    }
`;

import styled from 'styled-components';
import { screenSizes } from 'common/utils/constants/screen-sizes.constants';
import {fontFamily} from 'common/utils/constants/font.constants';
import { Link as LinkReactRouter } from 'react-router-dom';

const Wrapper = styled.div`
  height: 145px;
  display: inline-flex;
  margin-left:auto;

  @media (max-width:${screenSizes.W768}){
    display:none;        
  }
  @media (max-width:${screenSizes.W1024}) and (min-width:${screenSizes.W768}){
    display: none;
  }
`;
const Ul = styled.ul`
  list-style-type: none;
  display:inline-flex;
  margin: 0;
  padding: 0;
`;
const Li = styled.li`
  padding-top:30px;
  text-align: center;
  font-family: font;
  color: #fff;
`;
const SignUpBtn = styled(LinkReactRouter)`
  text-decoration: none;
  font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};;
  font-size: 22px;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  width: 126px;
  height: 51px;
  line-height: 51px;
  display:block;
  text-align:center;
  &:hover{
    color:#3bade3;
  }
  &:active{
    color:#3bade3;
  }
`;

const LogInBtn = styled(LinkReactRouter)`
  text-decoration: none;
  font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};;
  font-size: 22px;
  margin-top: auto;
  margin-bottom: auto;
  width: 110px;
  height: 51px;
  line-height: 51px;
  display:block;
  text-align:center;
  border: 1px solid;
  border-color:white;
  color:white;
  border-radius:5px;
  transition:0.5s;

  &:hover{
    color:#3bade3;
    border-color:#3bade3;
    
  }
  &:active{
    color:#3bade3;
  }
`;


export { Wrapper,SignUpBtn,LogInBtn ,Ul,Li };
import * as yup from 'yup';
// Form values
export interface IInnerFormValues {
  senderCountry: string;
  tgoOutlet: string;
  receiverCountry: string;
  receiverPostalCode:string;
  quotedParcelWeight:number;
  quotedParcelPackaging:string;
}

const requiredMsg = 'this field is required *'; 
const weightMinDocMsg = ' min weight is 0.5kg';
const weightMaxDocMsg = ' max weight is 30kg';
const weightMinNonDocMsg = ' min weight is 1kg';
const weightMaxNonDocMsg = ' max weight is 30kg';
const numberStringMsg=' must be number';
//Validation Schema
export const validationSchema = yup.object().shape({
  senderCountry: yup
    .string()
    .required(requiredMsg),
  tgoOutlet: yup
    .string()
    .required(requiredMsg),
  receiverCountry: yup
    .string()
    .required(requiredMsg),
  receiverPostalCode: yup
    .string()
    .matches(/^[0-9]+$/ , numberStringMsg)
    .required(requiredMsg),
  quotedParcelWeight: yup
    .number()
    .when('quotedParcelPackaging', {
      is: 'document',
      then: yup.number().min(0.5,weightMinDocMsg).max(30,weightMaxDocMsg)
    })
    .when('quotedParcelPackaging', {
      is: 'non-document',
      then: yup.number().min(1,weightMinNonDocMsg).max(30,weightMaxNonDocMsg)
    })
    .required(requiredMsg),
  quotedParcelPackaging: yup
    .string()
    .required(requiredMsg),
});
import { combineReducers } from 'redux';
import userReducer from'./userReducer';

// The Banker
// A reducer collates all the dispatched action and manipulate the store

const reducers = combineReducers({
  userState: userReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>
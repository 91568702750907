import React from 'react';
import MemberBooking from './MemberBooking';
import MemberProfile from './MemberProfile';
import { Wrapper } from './styles';

const Member:React.FC = () => {  
  return(
    <Wrapper>
            Member Details 1212-Andy Neo
            
      <MemberBooking></MemberBooking>
      <MemberProfile></MemberProfile>
    </Wrapper>
  );
};
export { Member };

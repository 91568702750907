import React from 'react';
import { Wrapper, Backlink, ForgotPasswordWrapper, Header1, BodyText, AntdInputEmail , AntdButtonSubmit ,Row} from './styles';
import { Input, Button} from 'antd';

const ForgotPassword:React.FC = () => {  
  return(
    <Wrapper>
      <Backlink>{'< Back'}</Backlink>
      <ForgotPasswordWrapper>
        <Header1>Reset password</Header1>
        <BodyText>Enter your user account verified email address and we will send you a password reset link </BodyText>
        <AntdInputEmail>
          <Input placeholder="Enter your email address" />
        </AntdInputEmail>
        <Row>
          <AntdButtonSubmit>
            <Button type="primary">Submit</Button>
          </AntdButtonSubmit>
        </Row>
      </ForgotPasswordWrapper>
    </Wrapper>
  );
};
export {ForgotPassword};

import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 50px 50px 50px 50px; 
    border-radius: 10px;
    box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 30px;
`;
const LogDiv = styled.div`
    margin-top: 20px;
`;

export { Wrapper, LogDiv };
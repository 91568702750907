import React, { Fragment } from 'react';
import { Nav,Ul,Li, Link } from './styles';

const MainMenu:React.FC = () => {   
  return(
    <Fragment>
      <Nav>
        <Ul>
          <Li><Link to="/staff/dashboard" >Dashboard</Link></Li>
          <Li><Link to="/staff/booking" >Bookings</Link></Li>   
          <Li><Link to="/staff/account/account-management" >Accounts</Link></Li>           
        </Ul>
      </Nav>
    </Fragment>
  );
};
 
export default MainMenu;

import colors from 'common/utils/constants/colors.constants';
import font from'common/utils/constants/font.constants';
import styled from 'styled-components';
import { Input as AntdFormikInput } from 'formik-antd';
import { Form as AntdFormikForm } from 'formik-antd';

const { TextArea } = AntdFormikInput;

export const Wrapper= styled.div`
`;
export const TextWrapper = styled.div`
   margin:0px 20px;
`;
export const AntdTextArea = styled(TextArea)`
    /* background-color: ${colors.LIGHT_GREY}; */
    padding:12px 20px;
    font: ${font.TEXT_M};
    color:#535353;
`;
export const AntdForm = styled(AntdFormikForm)`
`;


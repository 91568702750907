import React from 'react';
import { Wrapper, Pg5Wrapper, Pg5Column, Pg5Row, Pg5Header1,Pg5Header2,Pg5Text, PgFooterRow } from './styles';
import { IParcelReq, IParcelRes } from 'pages/customer-portal/parcel-booking/api/api.interface';
import {IBooking} from 'pages/customer-portal/parcel-booking/api/schema.interface';
import { getDateFromUnix, getTimeFromUnix } from 'common/utils/common.utils';
import { senderCountryListAlpha3, receiverCountryListAlpha3 } from 'common/utils/constants/countries.constants';
import Button from 'common/components/Button';
import { tgoOutletList} from 'common/utils/constants/tgo-outlets.constants';

import { useHistory } from 'react-router-dom';
import { postParcel } from 'pages/customer-portal/parcel-booking/api/api';
import { ILocationBookingSuccess } from 'pages/customer-portal/parcel-booking/api/location.interface';

const Page5:React.FC<{
    page:number,
    setPage:React.Dispatch<React.SetStateAction<number>>,
    bookingForm:IBooking
    }>= (props) => { 
        
      const parcelReq:IParcelReq={            
        'tgoOutlet': props.bookingForm.tgoOutlet,
        'sender':props.bookingForm.sender,
        'receiver':props.bookingForm.receiver,
        'quotedParcel':props.bookingForm.quotedParcel,
        'quotedCourier':props.bookingForm.quotedCourier,
        'discountCode':props.bookingForm.discountCode
      };

      const ConfirmButton:React.FC<{parcelReq:IParcelReq}>= (props) => {
        const history = useHistory();
        const onClickHandler=async (): Promise<void>=> {
          const res= await postParcel(props.parcelReq);
          console.log('resStatus :',res?.status);
        
          if (res?.status===200){
            const successRes =res as IParcelRes;
            console.log('postParcel-success: 200 ,parcel: ',successRes?.data?.parcel);
            // Success: Navigate to Booking Success Page
            const parcel=successRes?.data?.parcel;
            const locationBookingSuccess:ILocationBookingSuccess={
              bookingRef: parcel?.bookingRef,
              createdTimeStamp: parcel?.createdTimeStamp,
              tgoOutlet: parcel?.tgoOutlet,
              deliveryDate: parcel?.quotedParcel?.deliveryDate
            }; 
            history.push({
              pathname:'/parcel-booking/success',
              state: locationBookingSuccess
            });
          }
          else if (res?.status === 400){
            console.log('postParcel-failure: 400');
            // Error-Validation Issues : Navigate to pg4 details, display errors.
          }
          else if (res?.status === 404){
            console.log('postParcel-failure: 404');
            // Error: Not Found
          }
        };
        return( 
          <Button variant='primary' onClick={onClickHandler}>Confirm</Button>
        );
      };

      const PrevButton:React.FC<{page:number,setPage:React.Dispatch<React.SetStateAction<number>>}>= (props) => {
        const onClickHandler=()=>{
          if(props.page>1){
            props.setPage(props.page-1);
          }
        };
        return( 
          <Button variant='secondary' className="ml-auto mr-0" onClick={onClickHandler}> Back</Button>
        );
      };

      return(
        <Wrapper>
          <Pg5Wrapper>
            <Pg5Row>
              <Pg5Header1>Booking Details</Pg5Header1>
            </Pg5Row>
            <Pg5Row>
              <Pg5Column className="w-50percent">
                <Pg5Column className="w-100percent">
                  <Pg5Header2>1. Selected T.GO outlet</Pg5Header2>
                  <Pg5Text>{tgoOutletList[props.bookingForm.tgoOutlet]}</Pg5Text>
                </Pg5Column>
                <Pg5Column className="w-100percent">
                  <Pg5Header2>2. Sender&apos;s details</Pg5Header2>
                  <Pg5Text>Name: {props.bookingForm.sender.name}</Pg5Text>
                  <Pg5Text>Company: {props.bookingForm.sender.company}</Pg5Text>
                  <Pg5Text>Email: {props.bookingForm.sender.email}</Pg5Text>
                  <Pg5Text>Contact: {props.bookingForm.sender.contactNo}</Pg5Text>
                  <Pg5Text>Address:{props.bookingForm.sender.address}</Pg5Text>
                  <Pg5Text>Country:{senderCountryListAlpha3[props.bookingForm.sender.country]}</Pg5Text>
                  <Pg5Text>Suburb:{props.bookingForm.sender.suburb}</Pg5Text>
                  <Pg5Text>City:{props.bookingForm.sender.city}</Pg5Text>
                  <Pg5Text>State:{props.bookingForm.sender.state}</Pg5Text>
                  <Pg5Text>Postcode:{props.bookingForm.sender.postalCode}</Pg5Text>
                </Pg5Column>
                <Pg5Column className="w-100percent">
                  <Pg5Header2>3. Receiver&apos;s details</Pg5Header2>
                  <Pg5Text>Name: {props.bookingForm.receiver.name}</Pg5Text>
                  <Pg5Text>Company: {props.bookingForm.receiver.company}</Pg5Text>
                  <Pg5Text>Email: {props.bookingForm.receiver.email}</Pg5Text>
                  <Pg5Text>Contact: {props.bookingForm.receiver.contactNo}</Pg5Text>
                  <Pg5Text>Address:{props.bookingForm.receiver.address}</Pg5Text>
                  <Pg5Text>Country:{receiverCountryListAlpha3[props.bookingForm.receiver.country]}</Pg5Text>
                  <Pg5Text>Suburb:{props.bookingForm.receiver.suburb}</Pg5Text>
                  <Pg5Text>City:{props.bookingForm.receiver.city}</Pg5Text>
                  <Pg5Text>State:{props.bookingForm.receiver.state}</Pg5Text>
                  <Pg5Text>Postcode:{props.bookingForm.receiver.postalCode}</Pg5Text>
                </Pg5Column>
              </Pg5Column>
              <Pg5Column className="w-50percent">
                <Pg5Column className="w-100percent">
                  <Pg5Header2>4. Parcel details</Pg5Header2>
                  <Pg5Text>Contents: {props.bookingForm.quotedParcel.content}</Pg5Text>
                  <Pg5Text>Courier: {props.bookingForm.quotedCourier.courier+' '+props.bookingForm.quotedCourier.service}</Pg5Text>
                  <Pg5Text>Parcel type: {props.bookingForm.quotedParcel.packaging}</Pg5Text>
                  <Pg5Text>Weight (kg): {props.bookingForm.quotedParcel.weight.toFixed(1)+' kg'}</Pg5Text>
                  <Pg5Text>Dimension: {props.bookingForm.quotedParcel.dimension}</Pg5Text>
                  <Pg5Text>Parcel Value: {'S$'+props.bookingForm.quotedParcel.value.toFixed(2)}</Pg5Text>
                  <Pg5Text>Drop-off date: {getDateFromUnix(props.bookingForm.quotedParcel.deliveryDate)}</Pg5Text>
                  <Pg5Text>Drop-off time: {getTimeFromUnix(props.bookingForm.quotedParcel.deliveryDate)}</Pg5Text>
                  <Pg5Text>Estimated price (SGD):{'S$'+props.bookingForm.quotedCourier.price.toFixed(2)}</Pg5Text>
                  <Pg5Text>Discount-code:{props.bookingForm.discountCode}</Pg5Text>
                </Pg5Column>
              </Pg5Column>
            </Pg5Row>
          </Pg5Wrapper>
          <PgFooterRow>
            <PrevButton page={props.page} setPage={props.setPage}></PrevButton>
            <ConfirmButton 
              parcelReq={parcelReq}
            ></ConfirmButton>
          </PgFooterRow>
        </Wrapper>
      );
    };
 
export default Page5;
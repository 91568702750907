import styled from 'styled-components';
import { screenSizes } from 'common/utils/constants/screen-sizes.constants';

const Wrapper= styled.div`
  display:flex;
  margin-left: auto;
  @media (min-width:${screenSizes.W1024}){
    display:none;
  }
`;
const HamburgerLogo=styled.div`
  padding-top: 40px;
  margin-right:30px;

  @media (max-width:${screenSizes.W640}){ 
    padding-top: 20px;  
    margin-right:10px;
  }
`;

export { Wrapper,HamburgerLogo};
import styled from 'styled-components';


const Container = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');
  display: flex;
  flex-direction:column;
  min-height: 100vh;
  background-color: #fff;
`;

const Button = styled.button`
  min-width: 100px;
  padding: 16px 32px;
  border-radius: 4px;
  border: none;
  background: #141414;
  color: #fff;
  font-size: 22px;
  cursor: pointer;
`;

export {Container,Button};
import * as yup from 'yup';
// Form values
export interface IInnerFormValues {
  tgoOutlet: string;

  senderName: string;
  senderCompany: string;
  senderEmail: string;
  senderContactNo: string;
  senderAddress: string;
  senderSuburb: string,
  senderCity: string,
  senderState: string,
  senderPostalCode: string;
  senderCountry: string;

  receiverName: string;
  receiverCompany: string;
  receiverEmail: string;
  receiverContactNo: string;
  receiverAddress: string;
  receiverSuburb: string,
  receiverCity: string,
  receiverState: string,
  receiverPostalCode: string;
  receiverCountry: string;

  quotedParcelContent:string;
  quotedParcelDeliveryDate:number;
  quotedParcelValue:number;
  quotedParcelPackaging:string;
  quotedParcelDimension:string;
  quotedParcelWeight:number;

  quotedCourierCourier: string;
  quotedCourierPrice: number;
  quotedCourierService: string;
}

const requiredMsg = ' this field is required *';  
const weightdMsg = ' must be more than 0';
const numberStringMsg=' must be number';

//Validation Schema
export const validationSchema = yup.object().shape({
  tgoOutlet: yup
    .string()
    .required(requiredMsg),
  senderName: yup
    .string()
    .required(requiredMsg),
  senderCompany: yup
    .string(),
  senderEmail: yup
    .string()
    .email()
    .required(requiredMsg),
  senderContactNo: yup
    .string()
    .required(requiredMsg),
  senderAddress: yup
    .string()
    .required(requiredMsg),
  senderSuburb: yup
    .string(),
  senderCity: yup
    .string()
    .required(requiredMsg),
  senderState: yup
    .string()
    .required(requiredMsg),
  senderPostalCode: yup
    .string()
    .matches(/^[0-9]+$/ , numberStringMsg)
    .required(requiredMsg),
  senderCountry: yup
    .string()
    .required(requiredMsg),
  receiverName: yup
    .string()
    .required(requiredMsg),
  receiverCompany: yup
    .string(),
  receiverEmail: yup
    .string()
    .email()
    .required(requiredMsg),
  receiverContactNo: yup
    .string()
    .required(requiredMsg),
  receiverAddress: yup
    .string()
    .required(requiredMsg),
  receiverSuburb: yup
    .string(),
  receiverCity: yup
    .string()
    .required(requiredMsg),
  receiverState: yup
    .string()
    .required(requiredMsg),
  receiverPostalCode: yup
    .string()
    .matches(/^[0-9]+$/ , numberStringMsg)
    .required(requiredMsg),
  receiverCountry: yup
    .string()
    .required(requiredMsg),
  quotedParcelContent: yup
    .string()
    .required(requiredMsg),
  quotedParcelDeliveryDate: yup
    .number()
    .required(requiredMsg),
  quotedParcelValue: yup
    .string()
    .required(requiredMsg),
  quotedParcelPackaging: yup
    .string()
    .required(requiredMsg),
  quotedParcelDimension: yup
    .string(),
  quotedParcelWeight: yup
    .number()
    .moreThan(0,weightdMsg)
    .required(requiredMsg),
  quotedCourierCourier: yup
    .string()
    .required(requiredMsg),
  quotedCourierPrice: yup
    .number()
    .moreThan(0,weightdMsg)
    .required(requiredMsg),
  quotedCourierService: yup
    .string()
    .required(requiredMsg),
});
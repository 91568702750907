import React from 'react';
import { Form as AntdForm } from 'formik-antd';
import { Select } from './styles';
import { Wrapper } from './styles';
import 'antd/dist/antd.css';
import { tgoOutletList, tgoOutletTypesDisabled } from 'common/utils/constants/tgo-outlets.constants';
import { packagingList } from 'common/utils/constants/packaging.constants';
import { parcelStatusPendingList,parcelStatusReceivedList,parcelStatusList } from 'common/utils/constants/parcel-status.constants';

const { Option } = Select; // imports antDesign
const FormItem= AntdForm.Item;

interface Props {
  name:string;
  placeholder?:string;
  variant: 'tgo-outlet'|'packaging'|'parcel-status-pending'|'parcel-status-received'|'parcel-status';
  disabled?:boolean;
}

export const FormSelect:React.FC<Props> = (props) => {
  return ( 
    <Wrapper>
      <FormItem name={props.name}>
        <Select placeholder={props.placeholder} name={props.name} style={{ textAlign: 'left' }} disabled={(props.disabled)}>
          {props.variant==='tgo-outlet'?Object.entries(tgoOutletList).map( 
            ([key, value]) => <Option key={key} value={key} disabled={(tgoOutletTypesDisabled.includes(key))}>{value}</Option> 
          ):null}
          {props.variant==='packaging'?Object.entries(packagingList).map( 
            ([key, value]) => <Option key={key} value={key}>{value}</Option> 
          ):null}
          {props.variant==='parcel-status-pending'?Object.entries(parcelStatusPendingList).map( 
            ([key, value]) => <Option key={key} value={key}>{value}</Option> 
          ):null}
          {props.variant==='parcel-status-received'?Object.entries(parcelStatusReceivedList).map( 
            ([key, value]) => <Option key={key} value={key}>{value}</Option> 
          ):null}
          {props.variant==='parcel-status'?Object.entries(parcelStatusList).map( 
            ([key, value]) => <Option key={key} value={key}>{value}</Option> 
          ):null}
        </Select>
      </FormItem>
    </Wrapper>
  );
};
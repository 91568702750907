import React from 'react';
import {AntdInput, AntdForm, Wrapper} from './styles';
const FormItem = AntdForm.Item;

interface Props {
  name:string;
  placeholder?:string;
  help?:string;
  variant?: 'number'|'string';
  disabled?:boolean;
  value?:string;
}


export const FormInput:React.FC<Props> = (props) => {
  return ( 
    <Wrapper>
      <FormItem name={props.name} help={props.help} status="error">
        {props.value?
          <AntdInput name={props.name} placeholder={props.placeholder} type={(props.variant==='number')?'number':''} disabled={(props.disabled)} value={props.value}></AntdInput>:
          <AntdInput name={props.name} placeholder={props.placeholder} type={(props.variant==='number')?'number':''} disabled={(props.disabled)} ></AntdInput>
        }
      </FormItem>
    </Wrapper>
  );
};
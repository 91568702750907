import React from 'react';
import { Wrapper } from './styles';
import { useLocation } from 'react-router';
import SuccessBooking from './components/SuccessBooking/index';
import { ILocationBookingSuccess } from '../api/location.interface';

const ParcelBookingSuccess:React.FC = () => {  
  const location= useLocation();
  return(
    <Wrapper>
      <SuccessBooking locationBookingSuccess={location.state as ILocationBookingSuccess}></SuccessBooking>
    </Wrapper>
  );
};
export default ParcelBookingSuccess;

import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { loadUserState,saveUserState } from 'state/local-storage/localStorage';

// inserting persistent state from localStorage to redux store
const persistedState=loadUserState();

// creating store
export const store = createStore(
  reducers,
  {userState:persistedState},
  applyMiddleware(thunk)
);

// subscribing to redux store change, update LocalStorage
store.subscribe(()=>{
  saveUserState(store.getState().userState);
});

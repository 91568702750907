import React, {useState} from 'react';
import { Wrapper , CourierWebWrapper, CourierRow, CourierColumn, CourierWrapper, CourierImg,CourierService, CourierPrice,CourierPriceLabel,CourierSortableWrapper ,CourierSortable,CourierButton, CourierPhoneWrapper} from './styles';
import { IBooking, ICourier } from 'pages/customer-portal/parcel-booking/api/schema.interface';
import { ICourierQuery } from 'pages/customer-portal/parcel-booking/api/api.interface';
import { Table} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect } from 'react';
import { getCouriers } from 'pages/customer-portal/parcel-booking/api/api';
import Text from 'common/components/Text';
import { COURIER, SERVICES_MAPPING } from 'common/utils/constants/couriers.constants';
import { SelectValue } from 'antd/lib/select';
import { Modal} from 'antd';


const Page2:React.FC<{bookingForm:IBooking,setBookingForm:React.Dispatch<React.SetStateAction<IBooking>>}>= (props) => { 
  // List Of Couriers  that matching the quoted parcel parameter.
  const defaultCouriers:ICourier[] = [];
  const [couriers, setCouriers] = useState<ICourier[]>(defaultCouriers);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalCourier, setModalCourier] = useState<ICourier>();
  
  useEffect(() => {
    const courierQuery:ICourierQuery={            
      'senderCountry':props.bookingForm.sender.country,
      'tgoOutlet':props.bookingForm.tgoOutlet,
      'receiverCountry':props.bookingForm.receiver.country,
      'postalCode':props.bookingForm.receiver.postalCode,
      'estimatedWeight':props.bookingForm.quotedParcel.weight,
      'packaging':props.bookingForm.quotedParcel.packaging
    };
    getCouriers(courierQuery,setCouriers);
  }, []);


  // Common: Image Logo
  const courierLogoSrc:{ [key: string]: string }={};
  courierLogoSrc[COURIER.DHL]='/images/couriers-logo/dhl.png';
  courierLogoSrc[COURIER.UPS]='/images/couriers-logo/ups.png';
  courierLogoSrc[COURIER.SFE]='/images/couriers-logo/sfe.png';
  courierLogoSrc[COURIER.CMLOG]='/images/couriers-logo/cmlog.png';

  // Common: setBookingForm when select button is clicked
  const handleSelectCourier=(courier:ICourier)=>{
    props.setBookingForm(prevState => {
      return { 
        ...prevState, 
        quotedCourier:{
          ...prevState.quotedCourier,
          serviceListId:courier.serviceListId,
          courier:courier.courier,
          eta:courier.eta,
          service:courier.service,
          serviceInfo:courier.serviceInfo,
          priceListId:courier.priceListId,
          price:courier.price,
        }
      };
    });
  };

  // Web: Couriers Table
  interface IData extends ICourier{
    key:string;
  }
  const data:IData[]=[];
  for (let i = 0; i < couriers.length; i++) {
    data.push({
      key:couriers[i].serviceListId+''+couriers[i].priceListId,
      serviceListId:couriers[i].serviceListId,
      courier:couriers[i].courier,
      service:couriers[i].service,
      serviceInfo:couriers[i].serviceInfo,
      eta:couriers[i].eta,
      priceListId:couriers[i].priceListId,
      price:couriers[i].price
    });
  }

  const columns:ColumnsType<IData>= [
    {
      title: 'Courier',
      dataIndex: 'courier',
      key:'courier',
      render: (text, record) => <div><CourierImg src={courierLogoSrc[record.courier]} alt={record.courier} className='webTable'></CourierImg></div>,
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.courier.localeCompare(b.courier),
  
    },
    {
      title: 'Service info',
      dataIndex: 'serviceInfo',
      render: (text, record) => <CourierService onClick={()=>showServiceInfoModal(record)}>{SERVICES_MAPPING[record.service]}</CourierService>,
      align: 'center'
    },
    {
      title: 'Transit time',
      dataIndex: 'eta',
      render: (text: string) => <div>{text}</div>,
      align: 'center',
      sorter: (a, b) => a.eta.localeCompare(b.eta),
    },
    {
      title: 'Estimated price',
      dataIndex: 'price',
      render: (text: string) => <CourierPrice>{'S$'+parseFloat(text).toFixed(2)}</CourierPrice>,
      align: 'center',
      sorter: (a, b) => a.price-b.price,
    },
    {
      title: '',
      key: 'key',
      dataIndex: 'key',
      render: (text, record) => (
        <CourierButton onClick={()=>handleSelectCourier(record)}>Select</CourierButton>
      ),
      width: '10%',
    }
  ];

  // Phone: Couriers Table
  // Sorting
  // use slice() to copy the array and not just make a reference
  const sortCouriers:ICourier[] = couriers.slice(0);
  const handleSort=(value:SelectValue)=>{
    if(value==='price'){
      sortCouriers.sort(function(a,b) {
        // Lowest - Highest Price
        return a.price - b.price;
      });
      setCouriers(sortCouriers);
    }
    if(value==='courier'){
      sortCouriers.sort(function(a,b) {
        // A-Z Courier
        const x = a.courier.toLowerCase();
        const y = b.courier.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      setCouriers(sortCouriers);
    }
    if(value==='eta'){
      sortCouriers.sort(function(a,b) {
        // (A-Z) Lowest to Highest ETA
        const x = a.eta.toLowerCase();
        const y = b.eta.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      setCouriers(sortCouriers);
    }
  };

  // ServiceInfo Modal
  const showServiceInfoModal = (courier:ICourier) => {
    setIsModalVisible(true);
    console.log(courier);
    setModalCourier(courier);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return(
    <Wrapper>
      <CourierWebWrapper>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowClassName={(record) => props.bookingForm.quotedCourier.priceListId===record.priceListId&&props.bookingForm.quotedCourier.serviceListId===record.serviceListId
            ? 'active' : ''}
        />
      </CourierWebWrapper>

      {/* Couriers Table Phone */}
      <CourierPhoneWrapper>
        <CourierSortableWrapper>
          <CourierSortable placeholder='sorting' onChange={handleSort}>
            <CourierSortable.Option value="courier">by Courier</CourierSortable.Option>
            <CourierSortable.Option value="price">by Price</CourierSortable.Option>
            <CourierSortable.Option value="eta">by Eta</CourierSortable.Option>
          </CourierSortable>
        </CourierSortableWrapper>
        {couriers.map((courier) => (
          <CourierWrapper key={courier.serviceListId+''+courier.priceListId} 
            className={(props.bookingForm.quotedCourier.priceListId===courier.priceListId&&props.bookingForm.quotedCourier.serviceListId===courier.serviceListId)
              ?'active':'inactive'}>
            <CourierColumn>
              <CourierRow>
                <CourierColumn className='courierLogo'>
                  <CourierImg src={courierLogoSrc[courier.courier]} placeholder={courier.courier}></CourierImg>
                </CourierColumn>
                <CourierColumn className='courierInfo'>
                  <Text fontSize='s'>{'Transit time: '+ courier.eta}</Text>
                  <CourierService onClick={()=>showServiceInfoModal(courier)}>{courier.service}</CourierService>
                  <CourierPrice>{'S$'+courier.price.toFixed(2)}</CourierPrice>
                  <CourierPriceLabel>Estimated price</CourierPriceLabel>
                </CourierColumn>
              </CourierRow>
              <CourierButton onClick={()=>handleSelectCourier(courier)}>Select</CourierButton>
            </CourierColumn>
          </CourierWrapper>
        ))}
      </CourierPhoneWrapper>
      <Modal title="Service info" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>{modalCourier?.service}</p>
        <p>{modalCourier?.serviceInfo}</p>
      </Modal>
    </Wrapper>
  );
};
 
export default Page2;
export enum PARCEL_STATUS {
  Pending = 'pending',
  Received = 'received',
  OutForDelivery = 'outForDelivery',
  Rejected = 'rejected'
}

export const parcelStatusMapping:{ [id: string] : string; }= {
  'pending':'Pending',
  'received':'Received',
  'outForDelivery':'Out-For-Delivery',
  'rejected':'Rejected',
};
export const parcelStatusPendingList = {
  'pending':'Pending',
  'received':'Received',
  'rejected':'Rejected',
};

export const parcelStatusReceivedList = {
  'received':'Received',
  'outForDelivery':'Out For Delivery',
  'rejected':'Rejected',
};

export const parcelStatusList = {
  'pending':'Pending',
  'received':'Received',
  'outForDelivery':'Out For Delivery',
  'rejected':'Rejected'
};
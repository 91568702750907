import React from 'react';

import {Switch, Route} from 'react-router-dom';
import { GlobalStyle } from './common/styles/globalStyles';
import { Container} from './AppStyles';
import 'antd/dist/antd.css';

import RouterCustomer from 'pages/customer-portal/Router';
import RouterStaff from 'pages/staff-portal/Router';


const App:React.FC = () => {
  return (
    <Container>
      <Switch>
        <Route path="/staff">
          <RouterStaff></RouterStaff>
        </Route>
        <Route path="/">
          <RouterCustomer></RouterCustomer>
        </Route>
      </Switch>
      <GlobalStyle></GlobalStyle>
    </Container>
  );
};
export default App;

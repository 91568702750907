import font,{fontWeight} from 'common/utils/constants/font.constants';
import styled, { css } from 'styled-components';
import colors from 'common/utils/constants/colors.constants';

interface Props {
  fontSize?: 'h1' | 'h2' |'h3' | 'h4' | 'h5' |'h6' | 'xl' | 'l' |'m' | 's' | 'xs'
}
const Text = styled.div<Props>`
  ${props => props.fontSize=== 'h1'
    ?css`
    font:${font.H1};
    font-weight:${fontWeight.H};
    color:${colors.DARK_GREY};
    padding:5px 0px;
    `:''}
  ${props => props.fontSize=== 'h2'
    ?css`
    font:${font.H2};
    font-weight:${fontWeight.H};
    color:${colors.DARK_GREY};
    padding:5px 0px;
  `:''}
  ${props => props.fontSize=== 'h3'
    ?css`
    font:${font.H3};
    font-weight:${fontWeight.H};
    color:${colors.DARK_GREY};
    padding:5px 0px;
  `:''}
  ${props => props.fontSize=== 'h4'
    ?css`
    font:${font.H4};
    font-weight:${fontWeight.H};
    color:${colors.DARK_GREY};
    padding:5px 0px;
  `:''}
  ${props => props.fontSize=== 'h5'
    ?css`
    font:${font.H5};
    font-weight:${fontWeight.H};
    color:${colors.DARK_GREY};
    padding:5px 0px;
  `:''}
  ${props => props.fontSize=== 'h6'
    ?css`
    font:${font.H6};
    font-weight:${fontWeight.H};
    color:${colors.DARK_GREY};
    padding:5px 0px;
  `:''}
  ${props => props.fontSize=== 'xl'
    ?css`
    font:${font.TEXT_XL};
    color:${colors.DARK_GREY2};
    padding:5px 0px;
  `:''}
  ${props => props.fontSize=== 'l'
    ?css`
    font:${font.TEXT_L};
    color:${colors.DARK_GREY2};
    padding:5px 0px;
  `:''}
  ${props => props.fontSize=== 'm'
    ?css`
    font:${font.TEXT_M};
    color:${colors.DARK_GREY2};
    padding:5px 0px;
  `:''}
  ${props => props.fontSize=== 's'
    ?css`
    font:${font.TEXT_S};
    color:${colors.DARK_GREY2};
    padding:5px 0px;
  `:''}
  ${props => props.fontSize=== 'xs'
    ?css`
    font:${font.TEXT_XS};
    color:${colors.DARK_GREY2};
    padding:5px 0px;
  `:''}
`; 

Text.defaultProps = {
  fontSize: 'm'
};

export default Text;

import React from 'react';
import Text from 'common/components/Text';
import { Modal as AntdModal, Button } from 'antd';
import { Input, Wrapper} from './styles';
import { useState } from 'react';
import { IBooking } from 'pages/customer-portal/parcel-booking/api/schema.interface';

export interface Props {
  isModalVisible:boolean;
  setIsModalVisible:React.Dispatch<React.SetStateAction<boolean>>;
  setBookingForm:React.Dispatch<React.SetStateAction<IBooking>>;
}

export const ModalDiscountCode:React.FC<Props> = (props) => {
  const isModalVisible=props.isModalVisible;
  const setIsModalVisible=props.setIsModalVisible;
  const [discountCode,setDiscountCode] = useState<string>('');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    props.setBookingForm(prevState => ({
      ...prevState,
      discountCode:discountCode}
    ));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDiscountValue=(e:React.ChangeEvent<HTMLInputElement>)=>{
    const passRegex=/^[0-9a-zA-Z]{0,10}$/.test(e.target.value);
    if (passRegex)setDiscountCode(e.target.value);
  };

  return (
    <Wrapper>
      <Button type="primary" onClick={showModal}>
      </Button>
      <AntdModal title="Apply Discount" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Text className='mt-30'>Discount code</Text>
        <Input placeholder="Please enter discount code here" value={discountCode} onChange={handleDiscountValue} ></Input>
        <Text fontSize='s'><i>*Discount will be reflected in final price.</i></Text>
      </AntdModal>
    </Wrapper>
  );
};
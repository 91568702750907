import React from 'react';
import { HamburgerLogo, Wrapper } from './styles';
import { Twirl as Hamburger } from 'hamburger-react';

const HamburgerIcon:React.FC<{isOpenHamburger:boolean, setOpenHamburger:React.Dispatch<React.SetStateAction<boolean>>}> = (props) => {   
  return(
    <Wrapper>
      <HamburgerLogo>
        <Hamburger size={20} color="#ffffff" toggled={props.isOpenHamburger} toggle={props.setOpenHamburger} />
      </HamburgerLogo>
    </Wrapper>
  );
};
export default HamburgerIcon;

import {Route, Switch, Redirect} from 'react-router-dom';

import Header from 'common/components/HeaderCustomer';
import ParcelBooking from 'pages/customer-portal/parcel-booking/ParcelBooking';
import ParcelBookingSuccess from 'pages/customer-portal/parcel-booking/ParcelBookingSuccess';
import ParcelTracking from 'pages/customer-portal/parcel-tracing/ParcelTracking';
import SignUp from 'pages/customer-portal/authentication/SignUp';
import LogIn from 'pages/customer-portal/authentication/LogIn';
import Member from 'pages/customer-portal/account/Member';
import React, { Fragment } from 'react';

const RouterCustomer:React.FC = () => {
  return (
    <Fragment>
      {/* Customer Portal */}
      <header>
        <Header></Header>
      </header>
      <main>
        <Switch>
          <Route path="/parcel-booking" exact>
            <ParcelBooking></ParcelBooking>
          </Route>
          <Route path="/parcel-booking/success">
            <ParcelBookingSuccess></ParcelBookingSuccess>
          </Route>
          <Route path="/parcel-tracking" exact>
            <ParcelTracking></ParcelTracking>
          </Route>
          <Route path="/sign-up" exact>
            <SignUp></SignUp>
          </Route>
          <Route path="/log-in" exact>
            <LogIn></LogIn>
          </Route>
          <Route path="/accounts/:id" exact>
            <Member></Member>
          </Route>
          <Route path="/modal">
          </Route> 
          <Route path="/">
            {/* Redirect all path to parcel-booking */}
            <Redirect to="/parcel-booking"></Redirect>
          </Route>
        </Switch>
      </main>
    </Fragment> 
  );
};
export default RouterCustomer;

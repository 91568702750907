import React from 'react';
import { Wrapper , PgHeaderPhone, BookingSuccessWrapper,BookingSuccessHeader1,BookingSuccessHeader2, BookingSuccessText} from './styles';
import { ILocationBookingSuccess } from 'pages/customer-portal/parcel-booking/api/location.interface';
import { getDateFromUnix, displayBookingRef} from 'common/utils/common.utils';
// import { useHistory } from 'react-router-dom';
import Button from 'common/components/Button';
import Text from 'common/components/Text';
import { tgoOutletList } from 'common/utils/constants/tgo-outlets.constants';


const SuccessBooking:React.FC<{locationBookingSuccess:ILocationBookingSuccess}>= (props) => {
  const deliveryDate = getDateFromUnix(props.locationBookingSuccess.deliveryDate);

  const ReturnButton:React.FC = () => {
    // const history = useHistory();
    const onClickHandler=()=>{
      // history.push('/parcel-tracking');
      window.location.href = 'https://www.tgo.com.sg/';
    };
    return(    
      <Button variant='primary' onClick={onClickHandler}>Return</Button>
    );
  };

  return(
    <Wrapper>
      <PgHeaderPhone><Text fontSize='h3'>Booking Successful!</Text></PgHeaderPhone>
      <BookingSuccessWrapper>
        <BookingSuccessHeader1>Booking Successful!</BookingSuccessHeader1>
        <BookingSuccessHeader2>Booking Reference</BookingSuccessHeader2>
        <BookingSuccessHeader2>{displayBookingRef(props.locationBookingSuccess.bookingRef)}</BookingSuccessHeader2>
        <BookingSuccessText>Please proceed to T.GO outlet at {tgoOutletList[props.locationBookingSuccess.tgoOutlet]} on {deliveryDate} with the booking reference and your unsealed parcel for staff check. Payment will be collected at the outlet. </BookingSuccessText>
      </BookingSuccessWrapper>
      <ReturnButton></ReturnButton>
    </Wrapper>
  );
};
 
export default SuccessBooking;
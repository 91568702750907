const headingSize = {
  'H1': '48px',
  'H2': '38px',
  'H3': '22px',
  'H4': '18px',
  'H5': '16px', 
  'H6': '14px',
};
const textSize = {
  'XL': '24px',
  'L': '20px',
  'M': '16px',
  'S': '14px', 
  'XS':'7px',
};
export const fontFamily={
  'Option1':'Source Sans Pro',
  'Option2':'BlinkMacSystemFont',
  'Option3': 'sans-serif',
  'HELVETICA':'Helvetica',
  'SOURCE_SANS_PRO':'Source Sans Pro',
};
export const fontWeight={
  'H':'600'
};


const font={
  'H1': headingSize.H1 + ' ' + fontFamily.HELVETICA,
  'H2': headingSize.H2 + ' ' + fontFamily.HELVETICA,
  'H3': headingSize.H3 + ' ' + fontFamily.HELVETICA,
  'H4': headingSize.H4 + ' ' + fontFamily.HELVETICA,
  'H5': headingSize.H5 + ' ' + fontFamily.HELVETICA,
  'H6': headingSize.H6 + ' ' + fontFamily.HELVETICA,
  'TEXT_XL': textSize.XL + ' ' + fontFamily.SOURCE_SANS_PRO,
  'TEXT_L': textSize.L + ' ' + fontFamily.SOURCE_SANS_PRO,
  'TEXT_M': textSize.M + ' ' + fontFamily.SOURCE_SANS_PRO,
  'TEXT_S': textSize.S + ' ' + fontFamily.SOURCE_SANS_PRO,
  'TEXT_XS': textSize.XS + ' ' + fontFamily.HELVETICA,
};

export default font;

import React, {useState} from 'react';
import { FormWrapper,FormTitle,FormExpandable,UpArrow } from './styles';
import Text from 'common/components/Text';

const FormDropDown:React.FC<{title:string}>= (props) => {
  const [ dropDown,setDropDown]  = useState<boolean>(true);
  return (
    <FormWrapper>
      <FormTitle onClick={()=>{setDropDown(prevState=>!prevState);}}>
        <Text fontSize='h4'>{props.title}</Text>
        <UpArrow className={dropDown?'dropDown':''}/>
      </FormTitle>
      <FormExpandable className={dropDown?'dropDown':''}>
        {props.children}
      </FormExpandable>
    </FormWrapper>
  );
};
 
export default FormDropDown;
 
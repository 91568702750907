import axios, { AxiosResponse } from 'axios';
import { IListOfBookingRes, IPutBookingSaveReq , IPutBookingReceivedReq,IPutBookingRejectedReq, IBookingDetailRes, IPutBookingOfdReq} from './api.interface';

const apiBaseURL = process.env.REACT_APP_API_URL || 'http://localhost:3030';

export const getAllBooking= ():IListOfBookingRes|any => {
  // Actual Response
  const url= `${apiBaseURL}/parcels`;
  console.log('getAllBooking API');
  return axios.get<IListOfBookingRes| any>(url,{
    headers: {
      'Content-Type': 'application/json'
    },
  })
    .then(response => {
      return response;
    }).catch( (error)=> {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('Failed: Server responded with error message', error.response);
        return (error.response);
      } else {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        console.log('Failed: No Responses from server', error.request);
        return error.request;
      }
    });
};

export const getOneBooking= (bookingRef:string):Promise<AxiosResponse<IBookingDetailRes> | boolean> => {
  // Actual Response
  const url= `${apiBaseURL}/parcels/`+bookingRef;
  console.log('getOneBooking API');
  return axios.get<IBookingDetailRes>(url,{
    headers: {
      'Content-Type': 'application/json'
    },
  })
    .then(response => {
      return response;
    }).catch(ex => {
      return false;
    });
};

export const putBookingSave= (booking:IPutBookingSaveReq):Promise<AxiosResponse<IBookingDetailRes> | boolean> => {
  // Actual Response
  console.log('putBookingSave API');
  const putBookingReq=booking;
  const url= `${apiBaseURL}/parcels/`+booking.bookingRef+'/save';
  const headers = {
  };
  return axios.put<IBookingDetailRes>(url,putBookingReq,headers)
    .then(response => {
      console.log(response);
      return response;
    }).catch(ex => {
      console.log(ex.response.data);
      return false;
    });
};

export const putBookingReceived= (booking:IPutBookingReceivedReq):Promise<AxiosResponse<IBookingDetailRes> | boolean> => {
  // Actual Response
  console.log('putBookingSave API');
  const putBookingReq=booking;
  const url= `${apiBaseURL}/parcels/`+booking.bookingRef+'/received';
  const headers = {
  };
  return axios.put<IBookingDetailRes>(url,putBookingReq,headers)
    .then(response => {
      console.log(response);
      return response;
    }).catch(ex => {
      console.log(ex.response.data);
      return false;
    });
};

export const putBookingRejected= (booking:IPutBookingRejectedReq):Promise<AxiosResponse<IBookingDetailRes> | boolean> => {
  // Actual Response
  console.log('putBookingRejected API');
  const putBookingReq=booking;
  const url= `${apiBaseURL}/parcels/`+booking.bookingRef+'/rejected';
  const headers = {
  };
  return axios.put<IBookingDetailRes>(url,putBookingReq,headers)
    .then(response => {
      console.log(response);
      return response;
    }).catch(ex => {
      console.log(ex.response.data);
      return false;
    });
};


export const putBookingOfd= (booking:IPutBookingOfdReq):Promise<AxiosResponse<IBookingDetailRes> | boolean> => {
  // Actual Response
  console.log('putBookingOfd API');
  const putBookingReq=booking;
  const url= `${apiBaseURL}/parcels/`+booking.bookingRef+'/out-for-delivery';
  const headers = {
  };
  return axios.put<IBookingDetailRes>(url,putBookingReq,headers)
    .then(response => {
      console.log(response);
      return response;
    }).catch(ex => {
      console.log(ex.response.data);
      return false;
    });
};
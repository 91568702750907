import styled from 'styled-components';
import colors from 'common/utils/constants/colors.constants';

const Wrapper = styled.div`
  background-color: ${colors.BLUE};
  min-height:calc((100vh - 145px));;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Form = styled.form`
  max-width: 600px;
  width: 100%;
  height:400px;
  padding:30px 50px 50px 50px;
  margin-top:100px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: ${colors.WHITE};
`;

const Header1 = styled.div`
    font-family: HelveticaNeue;
    font-size: 48px;
    margin: 10px 0px;
`;

const Row = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    margin-top:30px;
`;

const ForgetPassword = styled.text`
    margin-right: auto;
    color:${colors.DARK_BLUE};
    text-decoration:underline;
    &:hover{
        font-weight: 600;
        cursor: pointer;
    }
`;

export { Wrapper, Form, Header1 ,Row , ForgetPassword};
import styled from 'styled-components';
import { screenSizes } from 'common/utils/constants/screen-sizes.constants';
import font from 'common/utils/constants/font.constants';

const Wrapper= styled.div`
`;
const Pg1Wrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-size:18px;
  display:flex;
  flex-direction:column;
`;

const Pg1Label = styled.div`
  height:30px;
  text-align: left;
`;
const Pg1WeightNote = styled.div`
  text-align: justify;
  font:${font.TEXT_S};
  word-spacing: 4px;

  &>a {
    margin: 3px;
    font:${font.TEXT_S};
  }
`;
const FormRow = styled.div`
  @media (max-width:calc(${screenSizes.W480} - 1px)){ 
    display:flex;
    flex-direction:column; 

    &.formParcelWeight{
      flex-direction: column-reverse;
    }
  }
  @media (min-width:${screenSizes.W480}){  
    display:flex;
    flex-direction:row; 
  }
`;
const FormColumn = styled.div`
  @media (max-width:calc(${screenSizes.W480} - 1px)){ 
    display:flex;
    flex-direction:column; 
  }
  @media (min-width:${screenSizes.W480}){  
    display:flex;
    flex-direction:column; 
    padding:10px 20px; 
    width:50%;
  }

`;
const FormDiv = styled.div``;


export { Wrapper, Pg1Wrapper, Pg1Label, Pg1WeightNote, FormColumn, FormRow, FormDiv};
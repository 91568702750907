import React from 'react';
import { Wrapper } from './styles';

const ParcelTracking:React.FC = () => {  
  return(
    <Wrapper>
      Parcel- Tracking
    </Wrapper>
  );
};
export default ParcelTracking;

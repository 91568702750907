import React, { useState} from 'react';
import { Wrapper , PgFooterRow} from './styles';
import PageSteps from './components/PageSteps';
import Page1 from './components/Page1Quote/index';
import Page2 from './components/Page2Courier/index';
import Page3 from './components/Page3Declaration/index';
import Page4 from './components/Page4Details/index';
import Page5 from './components/Page5Confirm/index';
import Button from 'common/components/Button';

import { ICourier, ISender, IReceiver,IParcelQuoted , IDeclaration, IBooking} from 'pages/customer-portal/parcel-booking/api/schema.interface';
import { scrollUpWindow } from 'common/utils/common.utils';

const ParcelBooking:React.FC = () => {  
  const defaultCourier:ICourier = {
    'courier':'',
    'eta':'',
    'price':0.0,
    'service':'',
    'serviceInfo':'',
  };
  const defaultTgoOutlet = 'SGP_TJPG';
  const defaultSender:ISender = {
    'name':'',
    'company':'',
    'email':'',
    'contactNo':'',
    'address':'',
    'suburb': '',
    'city': '',
    'state': '',
    'postalCode':'',
    'country':'SGP',
    
  };
  const defaultReceiver:IReceiver = {
    'name':'',
    'company':'',
    'email':'',
    'contactNo':'',
    'address':'',
    'suburb': '',
    'city': '',
    'state': '',
    'postalCode':'',
    'country':''
  };
  const defaultParcelQuoted:IParcelQuoted = {
    'content':'',
    'deliveryDate':0,
    'value':0.0,
    'packaging':'non-document',
    'dimension':'',
    'weight':0.0
  };
  const defaultDeclaration:IDeclaration = {
    'termsAndConditions':false,
    'dangerousGoods':false
  };
  const defaultBookingForm:IBooking={
    tgoOutlet: defaultTgoOutlet,
    sender:defaultSender,
    receiver:defaultReceiver,
    quotedParcel:defaultParcelQuoted,
    quotedCourier:defaultCourier,
    discountCode:'',
  };

  // Page transitions
  const [page,setPage] = useState<number>(1);

  // Other States
  const [declarationCheckbox, setDeclarationCheckbox] = useState(defaultDeclaration);
    
  // Couier & Parcel States
  const [bookingForm,setBookingForm]=useState<IBooking>(defaultBookingForm);
  console.log('bookingForm!',bookingForm);
  const handleNextButton=()=>{
    if(page<5){
      setPage(page+1);
    }
  };
  const handleNextButtonPg2=()=>{
    //Check that courier is not empty 
    if(bookingForm.quotedCourier.courier==''){
      alert('Please select a Courier to proceed');
    }else{
      handleNextButton();
    }
  };
  const handlePrevButton=()=>{
    if(page>1){
      setPage(page-1);
    }
  };
  const prevButtonRender=()=>{
    if (page>1&&page<5){
      return(<Button variant="secondary" onClick={handlePrevButton}> Prev </Button>);
    }
  };
  const nextButtonRender=()=>{
    if (page===1 || page===4){
      return(null);
    }else if(page===2){
      return(<Button variant="primary" onClick={handleNextButtonPg2}> Next </Button>);
    }else if(page===3){
      if(declarationCheckbox.dangerousGoods && declarationCheckbox.termsAndConditions ){
        return(<Button variant="primary" onClick={handleNextButton}> Next </Button>);
      }else{
        return(<Button variant="primary" className='disabled' disabled onClick={handleNextButton}> Next </Button>);
      }
    }
  };

  return(
    <Wrapper>
      <PageSteps page={page} setPage={setPage}></PageSteps>
      <div>
        { page === 1 ? <Page1 setPage={setPage} bookingForm={bookingForm} setBookingForm={setBookingForm}/> : null }
        { page === 2 ? <Page2 bookingForm={bookingForm} setBookingForm={setBookingForm}/> : null }
        { page === 3 ? <Page3 declarationCheckbox={declarationCheckbox} setDeclarationCheckbox={setDeclarationCheckbox}/> : null }
        { page === 4 ? <Page4 setPage={setPage} bookingForm={bookingForm} setBookingForm={setBookingForm}/>: null }
        { page === 5 ? <Page5 page={page} setPage={setPage} bookingForm={bookingForm}/>: null }
      </div>
      <PgFooterRow>
        {prevButtonRender()}
        {nextButtonRender()}
        {/* Bugs Fixed: Weird Behaviour when type='submit' button is placed in nextButtonRender() */}
        { page === 1 ? <Button variant="primary" form='courier-form' type="submit"> Next </Button>: null }
        { page === 4 ? <Button variant="primary" onClick={scrollUpWindow} form='booking-form' type="submit"> Next </Button>: null }
      </PgFooterRow>
    </Wrapper>
  );
};
export default ParcelBooking;

import colors from 'common/utils/constants/colors.constants';
import font from'common/utils/constants/font.constants';
import styled from 'styled-components';
import { Input as AntdFormikInput } from 'formik-antd';
import { Form as AntdFormikForm } from 'formik-antd';

export const Wrapper= styled.div`
  height:70px;
  .ant-input-affix-wrapper > input.ant-input, .ant-input-affix-wrapper{
    background-color: ${colors.LIGHT_GREY};
    font: ${font.TEXT_M};
  }
`;
export const AntdInput = styled(AntdFormikInput.Password)`
    background-color: ${colors.LIGHT_GREY};
    padding:10px 10px;
    font: ${font.TEXT_M};
`;
export const AntdForm = styled(AntdFormikForm)`
`;


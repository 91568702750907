import styled from 'styled-components';
import { fontFamily } from 'common/utils/constants/font.constants';
import { NavLink as LinkReactRouter } from 'react-router-dom';

const Nav = styled.nav`
  font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline;
  margin-left: 10px;
`;
const Ul = styled.ul`
  list-style-type: none;
  display:inline-flex;
  margin: 0;
  padding: 0;
`;
const Li = styled.li`
  padding-top:50px;
  text-align: center;
  font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};;
  color: #fff;
`;
const A = styled.a`
  text-decoration: none;
  font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  width: 106px;
  height: 51px;
  line-height: 51px;
  display:block;
  text-align:center;
  &:hover{
    background-color: white;
    color:black;
  }
  &:active{
    background-color:white;
    color:black;
  }
`;
const Link = styled(LinkReactRouter)`
  text-decoration: none;
  font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  width: 106px;
  height: 51px;
  line-height: 51px;
  display:block;
  text-align:center;
  &:hover{
    color:#3bade3;
    }
  &.active{
    color:#3bade3;
  }
`;


export { Nav,Ul,Li,A, Link};
// 320px — 480px: Mobile devices.
// 481px — 768px: iPads, Tablets.  ( Main Nav Bar disappears)
// 769px — 1024px: Small screens, laptops.
// 1025px — 1200px: Desktops, large screens.
// 1201px and more — Extra large screens, TV.


export enum screenSizes {
    W480 = '480px',
    W600 = '600px', // only for Pg5
    W640 = '640px', // only for Customer Header
    W768 = '768px',
    W1024 = '1024px',
    W1200 = '1200px',
}
import React, {useState} from 'react';
import { Wrapper,OuterHeaderWrapper,HeaderWrapper, Logo , LogoImg} from './styles';

import MainMenu from './components/MainNavigation/index';
import SignUp from './components/SignUp/index';
import HamburgerIcon from './components/HamburgerIcon';
import HamburgerMenu from './components/HamburgerMenu';

const Header:React.FC = () => {   
  const [isOpenHamburger, setOpenHamburger] = useState(false);

  return(
    <Wrapper>
      <OuterHeaderWrapper> 
        <HeaderWrapper> 
          <Logo href="https://tgo.com.sg/"><LogoImg src="/images/tgo-logo-white.png" alt='Logo'></LogoImg></Logo>
          <MainMenu></MainMenu>
          <SignUp></SignUp>
          <HamburgerIcon isOpenHamburger={isOpenHamburger} setOpenHamburger={setOpenHamburger}></HamburgerIcon>
        </HeaderWrapper>
      </OuterHeaderWrapper>
      <HamburgerMenu isOpenHamburger={isOpenHamburger}></HamburgerMenu>
    </Wrapper>
  );
};
export default Header;

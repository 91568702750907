// tgoOutlet mapping
export type tgoOutletTypes='SGP_TJPG'|'SGP_TMP'|'SGP_JE';

export const tgoOutletList = {
  'SGP_TJPG' :'Tanjong Pager MRT Station',
  'SGP_TMP':'Tampines MRT Station (Coming Soon)',
  'SGP_JE':'Jurong East MRT Station (Coming Soon)'
};


export const tgoOutletTypesDisabled=['SGP_TMP','SGP_JE'];
import colors from 'common/utils/constants/colors.constants';
import font from'common/utils/constants/font.constants';
import styled from 'styled-components';
import { Input as AntdFormikInput } from 'formik-antd';
import { Form as AntdFormikForm } from 'formik-antd';

export const Wrapper= styled.div`
  height:68px;
  &.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
    background-color: ${colors.LIGHT_GREY2};
  }
  .ant-input[disabled]{
    background-color: ${colors.LIGHT_GREY2}!important;
    color:${colors.DARK_GREY2}!important;
  }
`;
export const AntdInput = styled(AntdFormikInput)`
    background-color: ${colors.LIGHT_GREY};
    padding:12px 10px;
    font: ${font.TEXT_M};
    color:#535353;
`;
export const AntdForm = styled(AntdFormikForm)`
`;


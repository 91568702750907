import styled from 'styled-components';
import { screenSizes } from 'common/utils/constants/screen-sizes.constants';
import font, {fontWeight} from 'common/utils/constants/font.constants';

const Wrapper = styled.div`
    margin-top:70px;
    margin-left: 10px;
    margin-right: 10px;
`;

const PageStepWrapper = styled.div`
    max-width:1100px;
    display:flex;
    margin-bottom:30px;
    margin-left: auto;
    margin-right: auto;

    &>.ant-steps-small .ant-steps-item-title{
      font:${font.H5};
      font-weight:${fontWeight.H};
      line-height:24px;
      
    }
    &>.ant-steps-small .ant-steps-item-description{
      font:${font.TEXT_S};
    }

    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #3bade3;
    border:none;
    }

    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #343434;
   
}


.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height:24px;
  font-size: 16px;
  line-height:22px;
}


    @media (max-width:calc(${screenSizes.W768} - 1px)){     
        display: none;
    }
`;

const PgHeaderPhone= styled.div`
  margin-bottom:30px;

  @media (min-width:${screenSizes.W768}){  
    display: none;   
  }
`;

export { Wrapper,PageStepWrapper, PgHeaderPhone };
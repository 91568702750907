import React from 'react';
import Text from 'common/components/Text';
import {FormRow,Checkbox} from './styles';

interface Props {
  name:string;
  labelName:string;
  disabled?:boolean;
}
 
const FormCheckBox:React.FC<Props> = (props) => {
  return (
    <FormRow>
      <Text fontSize='m'>{props.labelName}</Text>
      <Checkbox disabled={props.disabled} name={props.name} ></Checkbox>
    </FormRow>
  );
}; 

FormCheckBox.defaultProps = {
  disabled: false
};

export default FormCheckBox;

import styled from 'styled-components';
import colors from 'common/utils/constants/colors.constants';
import font, { fontWeight } from 'common/utils/constants/font.constants';
import { screenSizes } from 'common/utils/constants/screen-sizes.constants';
import { Select as AntdSelect } from 'antd';

const Wrapper = styled.div`
`;

const CourierWebWrapper = styled.div`
  border-radius: 10px ;
  box-shadow: 0 3px 13px 0 hsla(0, 0%, 0%, 0.16);
  background-color: #fff;
  margin-left: 10px;
  margin-right: 10px;

  tbody.ant-table-tbody > tr.ant-table-row.active > td{
    /* background-color: ${colors.WHITE};  
    color: ${colors.DARK_GREY}; */
    background-color: ${colors.LIGHT_BLUE}; 
  }

  .ant-table-column-title, .ant-table-thead > tr > th {
    font:${font.H6};
    color: ${colors.DARK_GREY};
    font-weight:${fontWeight.H};
    line-height:20px;
  }

  @media (max-width:calc(${screenSizes.W768} - 1px)){ 
    display:none;
  }
`;

const CourierPhoneWrapper = styled.div`
  @media (min-width:${screenSizes.W768}){  
    display:none;
  }
`;

const CourierRow = styled.div`
  display:flex;
  flex-direction:row; 
`;
const CourierColumn = styled.div`
  display:flex;
  flex-direction:column; 
  &.courierInfo{
    flex-grow: 1;
    padding-left: 20px;
  }
  &.courierLogo{
    width: 8em;
    margin: 1em 0em;
  }
`;

const CourierSortableWrapper = styled.div`
  display:flex;
  flex-direction:row; 
  margin:10px;
  & div.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    height: 44px;
    padding-top:6px;
    background-color: ${colors.LIGHT_GREY};
  }

  & div.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-color:#EEEEEE;
  }
  & div.ant-select:not(.ant-select-disabled):hover .ant-select-selector{

  }
`;
const CourierSortable= styled(AntdSelect)`
  flex-grow: 1;
`;
const CourierWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);
  margin: 20px;
  padding:20px;

  &.active{
    /* background-color: ${colors.WHITE}; //pg2 courier table bg */
    background-color: ${colors.LIGHT_BLUE}; 
  }
`;
const CourierImg= styled.img`
  object-fit: contain;
  max-height:70px;

  &.webTable{
    max-height: 50px;
  }

`;
const CourierService= styled.text`
  font:${font.TEXT_S};
  color:${colors.BLUE};
  text-decoration: underline;
  padding:5px 0px;

  &:hover{
    font-weight: bold;
    cursor: pointer;
  }
`;
const CourierPrice= styled.div`
  font:${font.TEXT_M};
  color:${colors.BLUE};
  font-weight: bold;
  padding:5px 0px;
`;
const CourierPriceLabel= styled.div`
  font:${font.TEXT_XS};
  color:${colors.DARK_GREY};
`;
const CourierButton = styled.button`
  padding: 9px 35px;
  margin: 20px 10px;
  border: none;
  border-radius: 5px;
  font: ${font.TEXT_M};
  box-shadow: 0 3px 13px 0 rgb(0 0 0 / 16%);

  color: ${colors.WHITE};
  background-color: ${colors.BLUE};

  &:hover{
    background-color: ${colors.DARK_BLUE};
    color: ${colors.WHITE};
    cursor: pointer;
  }
`;
export { Wrapper,CourierWebWrapper,CourierPhoneWrapper ,CourierRow, CourierColumn, CourierWrapper, CourierImg,CourierService, CourierPrice, CourierPriceLabel, CourierSortableWrapper ,CourierSortable, CourierButton};
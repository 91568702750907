import axios from 'axios';
import { ICourier } from './schema.interface';
import { ICourierQuery, ICourierRes, IParcelReq, IParcelRes} from './api.interface';
import { I4xxErrorRes,I5xxErrorRes } from '../../../../common/api/error-api.interface';
import { mockedCouriersRes, mockedParcelRes } from './mocked-res.api';

const apiBaseURL = process.env.REACT_APP_API_URL || 'http://localhost:3000';
const mockedResponse = false;


export const getCouriers= (query:ICourierQuery, setCouriers:React.Dispatch<React.SetStateAction<ICourier[]>>,setCouriersLoading?:React.Dispatch<React.SetStateAction<boolean>>,setCouriersError?:React.Dispatch<React.SetStateAction<string>>):null|void=> {
  // Mocked response
  if (mockedResponse){
    console.log(mockedCouriersRes);
    setCouriers(mockedCouriersRes);
    return null;
  }
  // Actual Response
  const url= `${apiBaseURL}/couriers?tgoOutlet=`+query.tgoOutlet+'&receiverCountry='+query.receiverCountry+'&postalCode='+query.postalCode+'&estimatedWeight='+query.estimatedWeight+'&packaging='+query.packaging;
  axios.get<ICourierRes>(url,{
    headers: {
      'Content-Type': 'application/json'
    },
  }).then(response => {
    console.log('getCourier',response);
    setCouriers(response.data.couriers);
    // setCouriersLoading(false);
    return null;
  }).catch( (error)=> {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log('getCourier ','Failed: Server responded with error message', error.response);
      setCouriers([]);
      // setCouriersError('error');
      // setCouriersLoading(false);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      console.log('getCourier ','Failed: No Responses from server', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('getCourier ','Failed: Setting up request error');
    }
    console.log(error.config);
  });
};

export const postParcel= (req: IParcelReq):IParcelRes|Promise<IParcelRes|I4xxErrorRes|I5xxErrorRes> =>{
  //Mocked Response
  if (mockedResponse){
    console.log(mockedParcelRes);
    return mockedParcelRes;
  }
  //Actual Response
  const accessToken='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MTUzMTNmMjg4YmJhZGNjM2QxYTA0NjkiLCJlbWFpbCI6Im4xczIyd3dzQG5ldy5jbyIsInR5cGUiOiJtZW1iZXIiLCJpYXQiOjE2MzI4MzYzODN9.7AnyTdSh-edxdR40UfHD0RE6P2EyfqzQkXQ5U9XGGu4';
  const headers = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  const postParcelJson=req;
  const url= `${apiBaseURL}/parcels/pending`;
  return axios.post<IParcelRes>(url,postParcelJson,headers)
    .then(response => {
      console.log(response);
      return response;
    }).catch((error)=> {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('Failed: Server responded with error message', error.response);
        return (error.response);
      } else {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        console.log('Failed: No Responses from server', error.request);
        return error.request;
      }
    });
};
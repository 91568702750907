import styled from 'styled-components';
import { screenSizes } from 'common/utils/constants/screen-sizes.constants';

const Wrapper = styled.div`
    margin-left:auto;
    margin-right:auto;
    @media (max-width:${screenSizes.W768}){
        width: 100%;
    }

    @media (max-width:${screenSizes.W1024}) and (min-width:${screenSizes.W768}){
        width: 95%;
    }
    @media (min-width:${screenSizes.W1024}){
        width: 95%;
        max-width:1200px;
    }
`;

const Header1 = styled.div`
    font-family: HelveticaNeue;
    font-size: 48px;
    font-weight: normal;
    margin-bottom: 10px;
    color: #000;
`;

const TableWrapper = styled.div`
    .ant-table{
        border-radius: 10px;
        box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);
        margin-top:30px;
        margin-bottom:30px;
    }
`;
const TableFilterRow = styled.div`
    display:flex;
    flex-direction:row;
`;

const TableFilterItem = styled.div`
    display:flex;
    flex-direction:column;
    margin-right: 30px;
    &.w-25percent{
        width:25%
    }
    &.w-15percent{
        width:15%
    }

`;
const StatusBtn = styled.div`
    width: 73px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: solid 1px #eee;
    background-color: #fafafa;
    margin-left:auto;
`;

const ModalBodyWrapper = styled.div`
    .ant-select, input, button{
        width:80%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top:20px;
        margin-bottom: 20px;
    }
    .ant-btn > span{
        display: flex;
        margin:auto;
    }
    
`;
export { Wrapper, TableWrapper , Header1, TableFilterRow, TableFilterItem, StatusBtn, ModalBodyWrapper};
import styled from 'styled-components';
export const FormWrapper = styled.div`
    border-radius: 10px;
    box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    font-size:18px;
    padding: 30px;
    margin-left: 10px;
    margin-right: 10px;
    display:flex;
    flex-direction:column;
    justify-content: right;
`;

export const FormRow = styled.div`
    display:flex;
    flex-direction:row;
    &.w-25percent{
        width:25%
    }
    &.w-33percent{
        width:33%
    }
    &.w-50percent{
        width:50%
    }
    &.w-66percent{
        width:66%
    }
    &.w-100percent{
        width:100%
    }
`;

export const FormColumn = styled.div`
    display:flex;
    flex-direction:column;
    margin-right:30px;
    &.w-25percent{
        width:25%
    }
    &.w-33percent{
        width:33%
    }
    &.w-50percent{
        width:50%
    }
    &.w-66percent{
        width:66%
    }
    &.w-100percent{
        width:100%
    }
`;

export const FormDiv = styled.div`
    &.h-25px{
        height:25px;
    }
`;


import styled from 'styled-components';
import { fontFamily } from 'common/utils/constants/font.constants';
import { DatePicker as AntdDatePicker, DatePickerProps } from 'formik-antd';
import colors from 'common/utils/constants/colors.constants';

const DatePicker = styled(AntdDatePicker)<DatePickerProps>`
    &.ant-picker{
        height:44px;
        background-color:${colors.LIGHT_GREY};
    }
    &.ant-picker:hover{
        cursor: pointer;
    }

    .ant-picker-input > input {
        color:#535353;
        font-size:18px;
        font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};;
    }
    .ant-picker-input > input:hover{
        cursor: pointer;
    }
`;



export default DatePicker;

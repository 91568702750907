import colors from 'common/utils/constants/colors.constants';
import styled, { css } from 'styled-components';
import font from'common/utils/constants/font.constants';

interface Props {
  variant?: 'primary' | 'secondary'
}

const Button = styled.button<Props>`
    padding: 9px 35px;
    margin: 20px 10px;
    border: none;
    border-radius: 5px;
    transition:0.5s;
    font: ${font.TEXT_L};
    box-shadow: 0 3px 13px 0 rgb(0 0 0 / 16%);
    ${props => props.variant === 'primary'
    ? css`
      color: ${colors.WHITE};
      background-color: ${colors.BLUE};
      &:hover{
        background-color: ${colors.DARK_BLUE};
        color: ${colors.WHITE};
        cursor: pointer;
      }
      &.disabled{
        background-color: ${colors.LIGHT_GREY2};
      }
    `: css`
        color: ${colors.BLACK};
        background-color: ${colors.WHITE};
        &:hover{        
            background-color: ${colors.WHITE};
            color: ${colors.BLUE};
            cursor: pointer;
        }
    `}
`;
Button.defaultProps = {
  variant: 'primary'
};
export default Button;
import styled from 'styled-components';

const Wrapper = styled.div`
    & label.ant-checkbox-wrapper.pg3-checkbox-input{
    margin:20px 20px 20px 56px
}
`;

const Pg3Wrapper = styled.div`
    min-height: 425px;
    border-radius: 10px;
    box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    display:flex;
    flex-direction:column;
    margin-right:10px;
    margin-left:10px;
    padding-bottom: 20px;
`;

const Pg3Row = styled.div`
    display:flex;
    &.mt-50px{
        margin-top:50px;
    }
`;

const Pg3Text = styled.div`
    margin-right:30px;
    margin-top:20px;
    text-align:left;
    font-size: 16px;
    color:#535353;
`;

export { Wrapper, Pg3Wrapper, Pg3Row , Pg3Text};
import React from 'react';
import { Form as AntdForm } from 'formik-antd';
import { Select } from './styles';
import { Wrapper } from './styles';
import 'antd/dist/antd.css';
import { receiverCountryListAlpha3, senderCountryListAlpha3 } from 'common/utils/constants/countries.constants';

const { Option } = Select; // imports antDesign
const FormItem= AntdForm.Item;

interface Props {
  name:string;
  placeholder?:string;
  variant: 'sender-country'|'receiver-country';
  disabled?:boolean;
}

export const FormSelectCountries:React.FC<Props> = (props) => {
  return ( 
    <Wrapper>
      <FormItem name={props.name}>
        <Select
          showSearch
          placeholder={props.placeholder} 
          name={props.name}
          disabled={(props.disabled)}
          optionFilterProp="children"
          filterOption={(input, option) =>{
            if(option!=undefined){
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }else{
              return false;
            }
          }}
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {props.variant==='sender-country'?Object.entries(senderCountryListAlpha3).map( 
            ([key, value]) => <Option key={key} value={key}>{value}</Option> 
          ):null}
          {props.variant==='receiver-country'?Object.entries(receiverCountryListAlpha3).map( 
            ([key, value]) => <Option key={key} value={key}>{value}</Option> 
          ):null}
        </Select>
      </FormItem>
    </Wrapper>
  );
};
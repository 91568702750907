import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'common/components/Button';


const BackButton:React.FC = () => {
  const history = useHistory();
  const handleBack=()=>{
    history.push('/staff/booking');
  };
  return(    
    <Button variant='secondary' onClick={handleBack}>Back</Button>
  );
};

export {BackButton} ;
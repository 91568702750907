import React from 'react';
import { Wrapper, Pg3Wrapper, Pg3Row, Pg3Text } from './styles';
import CheckBox from 'common/components/CheckBox';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { IDeclaration } from 'pages/customer-portal/parcel-booking/api/schema.interface';


const Page3:React.FC<{declarationCheckbox:IDeclaration, setDeclarationCheckbox:React.Dispatch<React.SetStateAction<IDeclaration>>}>= (props) => {  
  const handleChangeDeclarationTermsAndCondition=(e:CheckboxChangeEvent)=>{
    props.setDeclarationCheckbox(prevState => {
      return { 
        ...prevState, 
        termsAndConditions:e.target.checked
      };
    });
  };
  const handleChangeDeclarationDangerousGoods=(e:CheckboxChangeEvent)=>{
    props.setDeclarationCheckbox(prevState => {
      return { 
        ...prevState, 
        dangerousGoods:e.target.checked
      };
    });
  };

  return(
    <Wrapper>
      <Pg3Wrapper>
        <Pg3Row className="mt-50px">
          <CheckBox checked={props.declarationCheckbox.termsAndConditions} onChange={handleChangeDeclarationTermsAndCondition} className="pg3-checkbox-input"></CheckBox>
          <Pg3Text>I understand that all rates quoted are nett rate inclusive of shipping fee only and does not include address correction fee by courier, customs duty and taxes, temporary import export charges, import taxes or duties and shipment return charges.</Pg3Text>
        </Pg3Row>
        <Pg3Row>
          <CheckBox checked={props.declarationCheckbox.dangerousGoods} onChange={handleChangeDeclarationDangerousGoods} className="pg3-checkbox-input"></CheckBox>
          <Pg3Text>I certify that the particulars given in this declaration are correct and that this item does not contain any <a href='https://www.tgo.com.sg/faq/' target="_blank" rel="noopener noreferrer">dangerous goods </a> prohibited by legislation or by postal or customs regulations*</Pg3Text>
        </Pg3Row>
      </Pg3Wrapper>
    </Wrapper>
  );
};
 
export default Page3;
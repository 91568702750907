import styled from 'styled-components';
import { screenSizes } from 'common/utils/constants/screen-sizes.constants';
// 320px — 480px: Mobile devices.
// 481px — 768px: iPads, Tablets.  ( Main Nav Bar disappears)
// 769px — 1024px: Small screens, laptops.
// 1025px — 1200px: Desktops, large screens.
// 1201px and more — Extra large screens, TV.
const Wrapper= styled.div`
 display: flex;
 flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-right:auto;
  background-color: #010101;
  width:100%;
  max-width:1500px;
  height: 132px;
  @media (max-width:640px){    
    height: 90px;
  }
`;

const OuterHeaderWrapper = styled.div` 
  background-color: #010101;
  width:100%;
  display:flex;
`;

const LogoImg = styled.img`
  padding-top: 42px;
  max-width: 97px;
  margin-right: 15px;
  margin-left: 54px;
  object-fit: contain;
  text-align: center;
  color: white;

  @media (max-width:640px){    
    padding-top: 22px; 
    margin-left:15px;
  }
`;

const Logo = styled.a`
`;
export { Wrapper,OuterHeaderWrapper,HeaderWrapper, Logo, LogoImg };
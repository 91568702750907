import React from 'react';
import Text from 'common/components/Text';
import { AntdForm, Wrapper, AntdTextArea, TextWrapper} from './styles';
const FormItem = AntdForm.Item;

interface Props {
  name:string;
  label:string;
  placeholder?:string;
  help?:string;
  disabled?:boolean;
}


export const FormTextArea:React.FC<Props> = (props) => {
  return ( 
    <Wrapper>
      <TextWrapper>
        <Text fontSize='m'>{props.label}</Text>
      </TextWrapper>
      <FormItem name={props.name} help={props.help} status="error">
        <AntdTextArea disabled={props.disabled} showCount maxLength={100} rows={4} name={props.name} placeholder={props.placeholder}></AntdTextArea>
      </FormItem>
    </Wrapper>
  );
};

FormTextArea.defaultProps = {
  disabled: false
};

import React from 'react';
import { OuterHeaderWrapper,HeaderWrapper, Logo } from './styles';

import MainMenu from './components/MainNavigation/index';
import SignUp from './components/SignUp/index';

const Header:React.FC = () => {   
  return(
    <OuterHeaderWrapper> 
      <HeaderWrapper> 
        <a href="https://otsaw.com/"><Logo src="/images/tgo-logo-white.png" alt='Logo'></Logo></a>
        <MainMenu></MainMenu>
        <SignUp></SignUp>
      </HeaderWrapper>
    </OuterHeaderWrapper>
  );
};
export { Header };
import styled from 'styled-components';
import font from 'common/utils/constants/font.constants';
import colors from 'common/utils/constants/colors.constants';
import { Input as AntdInput } from 'antd';

const Wrapper = styled.div`
`;

const Input = styled(AntdInput)`
  font:${font.TEXT_S};
  background:${colors.LIGHT_GREY};
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 20px;
`;

const Text = styled.div`
  font:${font.TEXT_M};
  &.mt-30{
    margin-top:10px;
  }
`;

export {Wrapper,Input,Text};

import React,{useState, useEffect} from 'react';
import { Wrapper, Header1, PgBlackBtn, PgWhiteBtn, PgHeaderTab } from './styles';
import {BookingDetail as Detail} from './components/BookingDetail';
import BookingLogs from './components/BookingLogs';
import { useParams } from 'react-router-dom';
import {  displayBookingRef } from '../../../../common/utils/common.utils';
import { getOneBooking } from '../api/api';
import { IBooking } from 'pages/staff-portal/booking/api/schema.interface';
import { IBookingDetailRes } from '../api/api.interface';
import { AxiosResponse } from 'axios';
import { parcelStatusMapping } from 'common/utils/constants/parcel-status.constants';

const BookingDetail:React.FC = () => { 
  // Bookinf Ref from params
  const { bookingRef } = useParams<{ bookingRef: string }>();
  // Data
  const defaultdata:IBooking=
    {
      'receiver': {
        'name':'',
        'company':'',
        'email':'',
        'contactNo':'',
        'address':'',
        'suburb': '',
        'city': '',
        'state': '',
        'postalCode':'',
        'country':'SGP',
      },
      'sender': {
        'name':'',
        'company':'',
        'email':'',
        'contactNo':'',
        'address':'',
        'suburb': '',
        'city': '',
        'state': '',
        'postalCode':'',
        'country':'',
      },
      'quotedCourier': {
        'courier': '',
        'eta': '',
        'price': -1,
        'service': '',
        'serviceInfo': ''
      },
      'quotedParcel': {
        'weight': -1,
        'dimension': '',
        'deliveryDate': -1,
        'packaging':'',
        'value': -1,
        'content': '',
      },
      'finalCheck': {
        'noDangerousGood':false,
        'parcelWeight':false,
        'parcelSize':false,
        'fragileStickers':false,
        'senderReceiverDetails':false,
        'parcelDetails':false,
        'remarks':''
      },   
      'tgoOutlet': 'SGP_TJPG',
      'awbRef': '',
      'bookingRef': '',
      'createdTimeStamp':-1,
      'parcelStatus': '',
      '_id': '',
    };
  const [dataSource,setDataSource] = useState<IBooking>(defaultdata); //Master Source, only loaded in initial api call

  // Api
  /* This would be called on initial page load */
  useEffect(()=>{
    async function fetchMyAPI(){
      const bookingRes:boolean | AxiosResponse<IBookingDetailRes>=await getOneBooking(bookingRef);
      if(typeof(bookingRes)!='boolean'){
        console.log('success',bookingRes);
        setDataSource(bookingRes.data.parcel);
      }else{
        console.log('error');
      }
    }
    fetchMyAPI();
  }, []);

  // PageTab Transitions
  const [pgTab, setPgTab] = useState<boolean>(true);

  const handleClickPgTab1=()=>{
    setPgTab(true);
  };
  const handleClickPgTab2=()=>{
    setPgTab(false);
  };
  const isPgTabDetail=()=>{
    if (pgTab){
      return (
        <div>
          {dataSource.parcelStatus!=undefined?<div>{'Parcel Status: ' + parcelStatusMapping[dataSource.parcelStatus]}</div>:null}
          <Detail booking={dataSource} setBooking={setDataSource}></Detail>
        </div>);
    }
    return (null);
  };
  const isPgTabLogs=()=>{
    if (!pgTab)return (<BookingLogs></BookingLogs>);
    return (null);
  };

  return(
    <Wrapper>
      <Header1>Booking Details - {displayBookingRef(bookingRef)}</Header1>
      <PgHeaderTab>
        <PgBlackBtn className={(pgTab? 'active' : '')} onClick={handleClickPgTab1}>Details</PgBlackBtn>
        <PgWhiteBtn className={(!pgTab? 'active' : '')} onClick={handleClickPgTab2}>Logs</PgWhiteBtn>
      </PgHeaderTab>
      {isPgTabDetail()}
      {isPgTabLogs()}
    </Wrapper>
  );
};
export default BookingDetail;

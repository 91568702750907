import styled from 'styled-components';
import { screenSizes } from 'common/utils/constants/screen-sizes.constants';
import font, {fontWeight,fontFamily } from 'common/utils/constants/font.constants';
import { FormInput } from 'common/components/FormInput';
const Wrapper = styled.div`
`;

const FormRow = styled.div`
    display:flex;
    flex-direction:row;
    &.w-25percent{
        width:25%
    }
    &.w-33percent{
        width:33%
    }
    &.w-50percent{
        width:50%
    }
    &.w-66percent{
        width:66%
    }
    &.w-100percent{
        width:100%
    }
    @media (max-width:calc(${screenSizes.W768} - 1px)){ 
      display:flex;
      flex-direction:column; 
    }
`;

const FormColumn = styled.div`
  display:flex;
  flex-direction:column;
  @media (min-width:calc(${screenSizes.W768})){
    padding:0px 20px; 
    &.w-25percent{
      width:25%
    }
    &.w-33percent{
      width:33%
    }
    &.w-50percent{
      width:50%
    }
    &.w-66percent{
      width:66%
    }
    &.w-100percent{
      width:100%
    }
  }
  @media (max-width:calc(${screenSizes.W768} - 1px)){ 
    width:100%!important;
  }
`;
const DatePickerWrapper = styled.div`
  height:70px;
  display: flex;
  flex-direction: column;
`;

const DiscountCodeText = styled.div`
  font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};
  color:#3bade0;
  text-decoration: underline;
  margin-top:-18px;
  font: ${font.TEXT_S};

  :hover{
    font-weight: 900;
    text-decoration: underline;
    cursor:pointer;
  }
`;

export { Wrapper, FormRow, FormColumn,DatePickerWrapper, DiscountCodeText};
import styled from 'styled-components';
import { UpOutlined } from '@ant-design/icons';
 
const Wrapper = styled.div`
`;

const FormWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-size:18px;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top:10px;
  display:flex;
  flex-direction:column;
  justify-content: right;
`;
const FormExpandable = styled.div`
  max-height: 0;
  transition: 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  overflow: hidden;
  &.dropDown{
    max-height: 1300px;
    transition: 3000ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  }
`;
const UpArrow = styled(UpOutlined)`
  margin-left: auto;
  transition: 0.9s;
  &.dropDown{
    transition: 0.9s;
    transform: rotateZ(-180deg);
  }
`;

const FormTitle = styled.div`
    display:flex;
    flex-direction:row;
    cursor: pointer;
    padding: 5px 0px 20px 0px;
`;
 
export { Wrapper, FormTitle,UpArrow,FormWrapper, FormExpandable};
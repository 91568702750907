import React from 'react';
import { Modal as AntdModal, Button } from 'antd';

export interface Props {
  isModalVisible:boolean;
  setIsModalVisible:React.Dispatch<React.SetStateAction<boolean>>;
  variant:'error'|'success';
}

export const Modal:React.FC<Props> = (props) => {
  const isModalVisible=props.isModalVisible;
  const setIsModalVisible=props.setIsModalVisible;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
      </Button>
      <AntdModal title="Alert" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        {(props.variant==='error')?'Failed, Unable To Save , Please Check Your Form':null}
        {(props.variant==='success')?'Success, Saved':null}
      </AntdModal>
    </>
  );
};
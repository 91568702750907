export enum COURIER {
  DHL = 'DHL',
  SFE = 'SFE',
  UPS = 'UPS',
  CMLOG= 'CMLOG'
}

export enum CMLOG_SERVICES {
  DOMESTIC = 'Domestic',
}

export enum DHL_SERVICES {
  EXPRESS_EASY = 'ExpressEasy',
}

export enum SFE_SERVICES {
  STANDARD_EXPRESS = 'StandardExpress',
  ECONOMY_EXPRESS = 'EconomyExpress',
  DOMESTIC='Domestic'
}

export enum UPS_SERVICES {
  WORLDWIDE_EXPRESS = 'WorldwideExpress',
  WORLDWIDE_EXPRESS_SAVER = 'WorldwideExpressSaver',
  WORLDWIDE_EXPEDITED = 'WorldwideExpedited',
}

export const SERVICES_MAPPING:{ [key: string]: string} = {
  'Domestic':'Domestic',
  'ExpressEasy': 'Express Easy',
  'StandardExpress':'Standard Express',
  'EconomyExpress':'Economy Express',
  'WorldwideExpress':'Worldwide Express',
  'WorldwideExpressSaver':'Worldwide Express Saver',
  'WorldwideExpedited':'Worldwide Expedited'
};
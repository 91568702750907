import { DateTime } from 'luxon';

// export const isObject=(object:any):boolean=> {
//   return object != null && typeof object === 'object';
// };

//Scrool to Top Screen
export const scrollUpWindow=():void=>{
  window.scrollTo(0, 0);
};

// datetime function
export const getTimeFromUnix=(unixTime:number):string=> {
  const dateTime = DateTime.fromSeconds(unixTime/1000);
  return dateTime.setLocale('fr').toLocaleString(DateTime.TIME_24_SIMPLE);
};

export const getDateFromUnix=(unixTime:number):string=> {
  const dateTime = DateTime.fromSeconds(unixTime/1000);
  return dateTime.toFormat('dd-LLL-yyyy');
};

export const getCurrentTimeUnix=():number=>{
  // Defining Lodash variabl
  // Getting the timestamp
  const dt = DateTime.local();
  console.log(dt.toMillis());
  return dt.toMillis();
};

// TGO Mapping Functions
// ToDo

// Country Mapping Functions
// ToDo


// Rendering Methods BookingRef
export const displayBookingRef=(bookingRef:string):string=>{
  return 'TGO '+bookingRef;
};
export const stripBookingRef=(displayBookingRef:string):string=>{
  return displayBookingRef.substring(4); // remove `TGO `
};

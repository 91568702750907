import * as yup from 'yup';
// Form values
export interface IInnerFormValues {
  email:string;
  password:string;
}

const requiredMsg = 'This field is required *'; 

//Validation Schema
export const validationSchema = yup.object().shape({
  email:yup
    .string()
    .email()
    .required(requiredMsg),
  password:yup
    .string()
    .required(requiredMsg),
});
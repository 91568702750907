import React, { Fragment } from 'react';
import { Nav,Ul,Li,A, Link } from './styles';

const MainMenu:React.FC = () => {   
  return(
    <Fragment>
      <Nav>
        <Ul>
          <Li><Link activeClassName="active" to="/parcel-booking" >Book</Link></Li>
          <Li><A href="https://www.tgo.com.sg/track/" >Track</A></Li>
          <Li><A href="https://www.tgo.com.sg/faq/">FAQ</A></Li> 
          <Li><A href="https://www.tgo.com.sg/contact-us/">Contact Us</A></Li>            
        </Ul>
      </Nav>
    </Fragment>
  );
};
 
export default MainMenu;

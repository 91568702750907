import { Wrapper, TableWrapper, Header1, TableFilterRow, TableFilterItem, StatusBtn, ModalBodyWrapper } from './styles';
import { Table, Input} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DatePicker } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import React, { useState , useEffect, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button , Select} from 'antd';
import { getAllBooking } from '../api/api';
import { IBooking } from '../api/schema.interface';
import {  IListOfBookingRes } from '../api/api.interface';
import { getDateFromUnix,getTimeFromUnix, displayBookingRef, stripBookingRef } from 'common/utils/common.utils';
import { I4xxErrorRes , I5xxErrorRes} from 'common/api/error-api.interface';
import { tgoOutletTypes,tgoOutletList } from 'common/utils/constants/tgo-outlets.constants';
import { parcelStatusMapping} from 'common/utils/constants/parcel-status.constants';
import moment,{ Moment } from 'moment';
const { Option } = Select; // imports antDesign
const { RangePicker } = DatePicker;

interface IData {
  key: React.Key;
  no:number;
  deliveryDate:number;
  bookingRef: string;
  tgoOutlet: tgoOutletTypes;
  courier: string;
  awbRef:string;
  price:number;
  parcelStatus:string;
}

const columns:ColumnsType<IData>= [
  {
    title: 'No',
    dataIndex: 'no',
    render: (text: string) => <div>{text}</div>,
  },
  {
    title: 'Date',
    dataIndex: 'deliveryDate',
    defaultSortOrder: 'descend',
    sorter: (a:IData, b:IData) => a.deliveryDate - b.deliveryDate,
    render: (text: number) => <div>{getDateFromUnix(text)}</div>,
  },{
    title: 'Time',
    dataIndex: 'deliveryDate',
    defaultSortOrder: 'descend',
    sorter: (a:IData, b:IData) => a.deliveryDate - b.deliveryDate,
    render: (text: number) => <div>{getTimeFromUnix(text)}</div>,
  },
  {
    title: 'Ref',
    dataIndex: 'bookingRef',
    render: (text: string) => <div>{text}</div>,
  },
  {
    title: 'Outlet',
    dataIndex: 'tgoOutlet',
    render: (text: tgoOutletTypes) => <div>{tgoOutletList[text]}</div>,
  },
  {
    title: 'Courier',
    dataIndex: 'courier',
    render: (text: string) => <div>{text}</div>,
  },
  {
    title: 'AWB',
    dataIndex: 'awbRef',
    render: (text: string) => <div>{text}</div>,
  },
  {
    title: 'Status',
    dataIndex: 'parcelStatus',
    render: (text: string) => <div>{parcelStatusMapping[text]}</div>,
  },
];


const BookingAll:React.FC = () => {
  // Data to be obtained from UseEffect, Initialization
  const defaultData:IData[]=[];
  const dataSource = useRef<IData[]>(defaultData); //Master Source, only loaded in initial api call
  const [dataSourceTable,setDataSourceTable] = useState<IData[]>(defaultData); // Current Displayed data affected by Filters
  const filterBookingRef = useRef<string>('');
  const filterDeliveryStartDate =useRef<Moment|null>(moment());
  const filterDeliveryEndDate =useRef<Moment|null>(moment());
  const filterParcelStatus=useRef<string>('');

  // Api
  /* This would be called on initial page load */
  useEffect(()=>{
    async function fetchMyAPI(){
      const res:IListOfBookingRes|I4xxErrorRes|I5xxErrorRes=await getAllBooking();
      console.log('resStatus :',res?.status);
      if (res?.status===200){
        const successRes:IListOfBookingRes=res as IListOfBookingRes;
        console.log('getAllBookings-success: 200');
        // Success: Navigate to Booking Success Page
        const parcels:IBooking[]=successRes?.data?.parcels;
        const data:IData[]=[];
        for (let i = 0; i < parcels.length; i++) {
          data.push({
            key: i,
            no: i+1,
            deliveryDate: parcels[i].quotedParcel.deliveryDate,
            bookingRef: displayBookingRef(parcels[i].bookingRef),
            tgoOutlet:parcels[i].tgoOutlet,
            courier:parcels[i].quotedCourier.courier,
            awbRef:parcels[i].awbRef,
            price:parcels[i].quotedCourier.price,
            parcelStatus:parcels[i].parcelStatus||''
          });
        }
        dataSource.current=(data);
        handleAllFilters();
      }
      else if (res?.status === 400){
        console.log('getAllBookings-failure: 400');
        // Error handling
      }
      else if (res?.status === 404){
        console.log('getAllBookings-failure: 404');
        // Error: Not Found
      }
    }
    fetchMyAPI();
  }, []);

  // Filters Table
  const handleAllFilters=()=>{
    let filterData:IData[];
    filterData=dataSource.current.filter(booking => {return(booking.bookingRef.includes(filterBookingRef.current));});
    filterData=filterData.filter(booking => {
      if(filterDeliveryStartDate.current===null || filterDeliveryEndDate.current===null){
        return true;
      }else if (moment(booking.deliveryDate).isBetween(filterDeliveryStartDate.current, filterDeliveryEndDate.current, 'days', '[]')) {
        return true;
      }else{
        return false;
      }
    });
    filterData=filterData.filter(booking => {return(booking.parcelStatus.includes(filterParcelStatus.current));});
    setDataSourceTable(filterData);
  };
  const handleFilterBookingRef=(nameSearch:React.ChangeEvent<HTMLInputElement>)=>{
    filterBookingRef.current=(nameSearch.target.value);
    handleAllFilters();
  };
  const handleFilterDateRange=(date:RangeValue<Moment>)=>{
    if (date===null){
      filterDeliveryStartDate.current=null;
      filterDeliveryEndDate.current=null;
    }else {
      [filterDeliveryStartDate.current,filterDeliveryEndDate.current]=date;
    }
    handleAllFilters();
  };
  const handleFilterStatus=(value:string)=>{
    filterParcelStatus.current=value;
    handleAllFilters();
  };

  // Row clicks, navigate to Booking details
  const history = useHistory();
  const handleTableRowClick= (record:IData)=>{
    const bookingRef:string=stripBookingRef(record.bookingRef);
    history.push({
      pathname:'/staff/booking/'+bookingRef,
    });
  };

  // Row Selection Table Checkboxes
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IData[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    }
  };

  // Antd Modals
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleModalUpdate= () => {
    setIsModalVisible(false);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return(
    <Wrapper>
      <Header1>Bookings</Header1>
      <TableWrapper>
        <TableFilterRow>
          <TableFilterItem className="w-25percent">
            <Input.Search
              allowClear
              onChange={handleFilterBookingRef}
              placeholder="Search ref"
            />
          </TableFilterItem>
          <TableFilterItem>
            <RangePicker onChange={handleFilterDateRange} format="DD-MMM-YYYY" defaultValue={[moment(),moment()]} />
          </TableFilterItem>
          <TableFilterItem className="w-15percent">
            <Select defaultValue={''} onChange={handleFilterStatus}>
              <Option value=''>All</Option>
              <Option value='pending'>{parcelStatusMapping['pending']}</Option>
              <Option value='received'>{parcelStatusMapping['received']}</Option>
              <Option value='outForDelivery'>{parcelStatusMapping['outForDelivery']}</Option>
              <Option value='rejected'>{parcelStatusMapping['rejected']}</Option>
            </Select>
          </TableFilterItem>
          <StatusBtn>Up</StatusBtn>
          {/* <StatusBtn onClick={showModal}>Up</StatusBtn> */}
        </TableFilterRow>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={dataSourceTable}
          pagination={{ position: ['bottomCenter'] }}
          onRow={(rowData) => {
            return {
              onClick: () => {
                handleTableRowClick(rowData);
              }, // click row
            };
          }}
        />
      </TableWrapper>

      <Modal title="Update Status" visible={isModalVisible} footer={null} onCancel={handleModalCancel}>
        <ModalBodyWrapper>
          <Select defaultValue={''}>
            <Option value="" disabled>Out for delivery</Option>
            <Option value="received">Recieved</Option>
            <Option value="received1">Recieved</Option>
            <Option value="received2">Recieved</Option>
            <Option value="received3">Recieved</Option>
          </Select>
          <Input placeholder="Name of deliveryman" ></Input>
          <Button type="primary" style={{width:'100px',textAlign:'center'}} onClick={handleModalUpdate}>Update</Button>
        </ModalBodyWrapper>
      </Modal>
    </Wrapper>
  );
};
export default BookingAll;

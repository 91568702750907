import React from 'react';
import { Nav,Ul,Li,A, Link, Wrapper } from './styles';

const HamburgerMenu:React.FC<{isOpenHamburger:boolean}> = (props) => {   
  return(
    <Wrapper>
      <Nav className={props.isOpenHamburger?'active':'inactive'}>
        <Ul>
          <Li><Link activeClassName="active" to="/parcel-booking">Book</Link></Li>
          <Li><A href="https://www.tgo.com.sg/track/" >Track</A></Li>
          <Li><A href="https://www.tgo.com.sg/faq/">FAQ</A></Li> 
          <Li><A href="https://www.tgo.com.sg/contact-us/">Contact Us</A></Li>       
        </Ul>

        {/* <Ul>
          <Li><Link to="/sign-up">Sign Up</Link></Li>
          <Li><Link to="/log-in">Sign In</Link></Li>
        </Ul> */}
      </Nav>
    </Wrapper>
  );
};
 
export default HamburgerMenu;

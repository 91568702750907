import styled from 'styled-components';
import { fontFamily } from 'common/utils/constants/font.constants';
import { NavLink  as LinkReactRouter } from 'react-router-dom';

const Nav = styled.nav`
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline;

  @media (max-width:768px){
    display:none;        
  }
  @media (max-width:1025px) and (min-width:768px){
    display: none;
  }
`;
const Ul = styled.ul`
  list-style-type: none;
  display:inline-flex;
  margin: 0;
  padding: 0;
`;
const Li = styled.li`
  padding-top:35px;
  text-align: center;
  color: #fff;
`;
const A = styled.a`
  text-decoration: none;
  font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 30px;
  margin-right: 23px;
  height: 62px;
  line-height: 62px;
  display:block;
  text-align:center;
  &:hover{
    color:#3bade3;
  }
  &:active{
    color:#3bade3;
  }
`;
const Link = styled(LinkReactRouter)`
  text-decoration: none;
  font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 30px;
  margin-right: 23px;
  height: 62px;
  line-height: 62px;
  display:block;
  text-align:center;
  &:hover{
    color:#3bade3;
  }
  &.active{
    color:#3bade3;
  }
`;


export { Nav,Ul,Li,A, Link};
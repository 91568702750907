import React, { Fragment } from 'react';
import { Wrapper,SignUpBtn,LogInBtn,Ul,Li } from './styles';
const SignUp:React.FC = () => {   
  return(
    <Fragment>
      <Wrapper>
        <Ul>
          {/* <Li><SignUpBtn to="/sign-up" >Sign Up</SignUpBtn></Li>
          <Li><LogInBtn to="/log-in" >Log In</LogInBtn></Li>         */}
        </Ul>
      </Wrapper>
    </Fragment>
  );
};
 
export default SignUp;

// LocalStorage for data to persist between different login sessions
// Usage: UserState allow login data persist.
import { UserState } from '../reducers/userReducer';

export const loadUserState =()=>{
  try{
    const seralizedState = localStorage.getItem('userState');
    if(seralizedState===null){
      return undefined;
    } 
    return JSON.parse(seralizedState);
  }catch(err){
    return undefined;
  }
};
  
export const saveUserState =(state:UserState)=>{
  try{
    const serializedState=JSON.stringify(state);
    localStorage.setItem('userState',serializedState);
  }catch(err){
    // Catching errors here
    //stringify & localStorage.setItem might fail.
  }
};
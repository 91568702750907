import React from 'react';
import {AntdInput, AntdForm, Wrapper} from './styles';
const FormItem = AntdForm.Item;

interface Props {
  name:string;
  placeholder?:string;
  help?:string;
}

export const FormInputPassword:React.FC<Props> = (props) => {
  return ( 
    <Wrapper>
      <FormItem name={props.name} help={props.help} status="error">
        <AntdInput name={props.name} placeholder={props.placeholder}></AntdInput>
      </FormItem>
    </Wrapper>
  );
};
import { useSelector} from 'react-redux';
import { RootState } from 'state/reducers/index';
import { isEmpty } from 'lodash';
import { IUser } from 'pages/staff-portal/authentication/api/schema.interface';
import { USER_ROLES } from './constants/user-roles.constants';

// Checking is Authenticated
export const isAuthenticated=():boolean=>{
  const userStateRedux = useSelector((state: RootState) => state.userState);
  const role=userStateRedux.user?.role;
  if(role===USER_ROLES.MEMBER){
    return true;
  }
  return !isEmpty(userStateRedux);
};

export const isAuthenticatedStaff=():boolean=>{
  const userStateRedux = useSelector((state: RootState) => state.userState);
  if(!isEmpty(userStateRedux)){
    const role=userStateRedux.user?.role;
    if(role===USER_ROLES.ADMIN||role===USER_ROLES.SUPERVISOR||role===USER_ROLES.STAFF){
      return true;
    }
  }
  return false;
};

export const getUser=():IUser|undefined=>{
  const userStateRedux = useSelector((state: RootState) => state.userState);
  return userStateRedux.user;
};
import React from 'react';
import { Wrapper,PageStepWrapper, PgHeaderPhone  } from './styles';
import { Steps } from 'antd';
import Text from 'common/components/Text';
const { Step } = Steps;

const PageSteps:React.FC<{page:number, setPage:React.Dispatch<React.SetStateAction<number>>}>= (props) => {  
  return(
    <Wrapper>
      <PgHeaderPhone> 
        {props.page==1?<Text fontSize='h3'> Step 1: Get quote  </Text>:null}
        {props.page==2?<Text fontSize='h3'> Step 2: Choose a courier </Text>:null}
        {props.page==3?<Text fontSize='h3'> Step 3: Declaration </Text>:null}
        {props.page==4?<Text fontSize='h3'> Step 4: Fill up details </Text>:null}
        {props.page==5?<Text fontSize='h3'> Step 5: Confirmation </Text>:null}
      </PgHeaderPhone>
      <PageStepWrapper >
        <Steps size="small" current={props.page-1}>
          <Step title="Get quote" description=" " />
          <Step title="Choose a courier" description=" "/>
          <Step title="Declaration" description=" " />
          <Step title="Fill up details" description=" " />
          <Step title="Confirmation" description=" " />
        </Steps>
      </PageStepWrapper >
    </Wrapper>
  );
};
 
export default PageSteps;
import styled from 'styled-components';
import { screenSizes } from 'common/utils/constants/screen-sizes.constants';
import {fontFamily} from 'common/utils/constants/font.constants';

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

const FormRow = styled.div`
  display:flex;
  flex-direction:row;
  &.w-25percent{
      width:25%
  }
  &.w-33percent{
      width:33%
  }
  &.w-50percent{
      width:50%
  }
  &.w-66percent{
      width:66%
  }
  &.w-100percent{
      width:100%
  }
  @media (max-width:calc(${screenSizes.W768} - 1px)){ 
    display:flex;
    flex-direction:column; 
  }
`;

const FormColumn = styled.div`
  display:flex;
  flex-direction:column;
  @media (min-width:calc(${screenSizes.W768})){
    padding:0px 20px; 
    &.w-25percent{
      width:25%
    }
    &.w-33percent{
      width:33%
    }
    &.w-50percent{
      width:50%
    }
    &.w-66percent{
      width:66%
    }
    &.w-100percent{
      width:100%
    }
  }
  @media (max-width:calc(${screenSizes.W768} - 1px)){ 
    width:100%!important;
  }
`;

const DatePickerWrapper = styled.div`
  height:70px;
  display: flex;
  flex-direction: column;
`;
const PgFooterRow = styled.div`
    display:flex;
    flex-direction:row;
    @media (max-width:calc(${screenSizes.W480} - 1px)){     
        justify-content: center;  
    }
    @media (min-width:${screenSizes.W480}){  
        justify-content: right;
    }
`;
const DiscountCodeText = styled.div`
  font-family: ${fontFamily.Option1},${fontFamily.Option2},${fontFamily.Option3};
  color:#3bade0;
  text-decoration: underline;
  margin-top:-20px;
  

  :hover{
    font-weight: 900;
    text-decoration: underline;
    cursor:pointer;
  }
`;
export { Wrapper, FormRow, FormColumn, DatePickerWrapper,PgFooterRow, DiscountCodeText};
/* eslint-disable react/no-unescaped-entities */
import React , {useState}from 'react';
import {  Wrapper , FormRow, FormColumn,DatePickerWrapper, DiscountCodeText} from './styles';
import { IBooking } from 'pages/customer-portal/parcel-booking/api/schema.interface';
import { withFormik , FormikProps, Formik} from 'formik';
import { validationSchema, IInnerFormValues } from './validation';
import { FormSelect } from 'common/components/FormSelect';
import { FormSelectCountries } from 'common/components/FormSelectCountries';
import { FormInput } from 'common/components/FormInput';
import FormDropDown from 'common/components/FormDropDown';
import Text from 'common/components/Text';
import DatePicker from 'common/components/DatePicker';
import { getCurrentTimeUnix, scrollUpWindow } from 'common/utils/common.utils';
import moment,{ Moment } from 'moment';
import { ModalDiscountCode } from 'common/components/ModalDiscountCode';

// The type of props MyForm receives
interface OtherProps {
  bookingForm:IBooking;
  setModalVisible:React.Dispatch<React.SetStateAction<boolean>>;
}

const innerFormPg4= (props: OtherProps & FormikProps<IInnerFormValues>) => { 
  const { touched, errors, setFieldValue,values, setModalVisible,bookingForm } = props;
  const handleDatePickerOk=(value:Moment)=> {
    console.log('onOk: ', value.utc().valueOf());
    setFieldValue('quotedParcelDeliveryDate',value.utc().valueOf());
  };
  function disabledDate(current:Moment) {
    // Can not select days before today
    return current < moment().add(-1, 'days').endOf('day') || current > moment().add(30, 'days').endOf('day');
  }
  //Datetimepicker :Disable Time Selectors
  const disabledDateTime=()=> {
    return {
      disabledHours: () => [0,1,2,3,4,5,6,7,20,21,22,23]
    };
  };

  // Discount Code 
  const handleDiscountCode=()=>{
    setModalVisible(value=>!value);
    return true;
  };

  return(
    <form onSubmit={props.handleSubmit} id="booking-form">
      <FormDropDown title='1. Select T.GO outlet'>
        <FormRow>
          <FormColumn className="w-100percent">
            <FormSelect name="tgoOutlet" placeholder="TGO Outlet" variant='tgo-outlet'/>
          </FormColumn>
        </FormRow>
      </FormDropDown>
      <FormDropDown title='2. Sender&apos;s details'>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Sender's name</Text>
            <FormInput placeholder="Name" name='senderName' help={touched.senderName && errors.senderName ?errors.senderName:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Company</Text>
            <FormInput placeholder="If applicable" name='senderCompany' help={touched.senderCompany && errors.senderCompany ?errors.senderCompany:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Email</Text>
            <FormInput placeholder="Email" name='senderEmail' help={touched.senderEmail && errors.senderEmail ?errors.senderEmail:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Contact</Text>
            <FormInput placeholder="Contact number" name='senderContactNo' help={touched.senderContactNo && errors.senderContactNo ?errors.senderContactNo:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-100percent">
            <Text fontSize='m'>Address</Text>
            <FormInput placeholder="Address" name='senderAddress' help={touched.senderAddress && errors.senderAddress ?errors.senderAddress:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Suburb</Text>
            <FormInput placeholder="If applicable" name='senderSuburb' help={touched.senderSuburb && errors.senderSuburb ?errors.senderSuburb:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>City</Text>
            <FormInput placeholder="City" name='senderCity' help={touched.senderCity && errors.senderCity ?errors.senderCity:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>State</Text>
            <FormInput placeholder="State" name='senderState' help={touched.senderState && errors.senderState ?errors.senderState:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Postcode</Text>
            <FormInput placeholder="Postcode" name='senderPostalCode' help={touched.senderPostalCode && errors.senderPostalCode ?errors.senderPostalCode:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Country</Text>
            <FormSelectCountries disabled={true} name='senderCountry' variant='sender-country'></FormSelectCountries>
          </FormColumn>
        </FormRow>
      </FormDropDown>

      <FormDropDown title='3. Receiver&apos;s details'>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Receiver's name</Text>
            <FormInput placeholder="Name" name='receiverName' help={touched.receiverName && errors.receiverName ?errors.receiverName:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Company</Text>
            <FormInput placeholder="If applicable" name='receiverCompany' help={touched.receiverCompany && errors.receiverCompany ?errors.receiverCompany:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Email</Text>
            <FormInput placeholder="Email" name='receiverEmail' help={touched.receiverEmail && errors.receiverEmail ?errors.receiverEmail:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Contact</Text>
            <FormInput placeholder="Contact number" name='receiverContactNo' help={touched.receiverContactNo && errors.receiverContactNo ?errors.receiverContactNo:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-100percent">
            <Text fontSize='m'>Address</Text>
            <FormInput placeholder="Address" name='receiverAddress' help={touched.receiverAddress && errors.receiverAddress ?errors.receiverAddress:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Suburb</Text>
            <FormInput placeholder="If applicable" name='receiverSuburb' help={touched.receiverSuburb && errors.receiverSuburb ?errors.receiverSuburb:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>City</Text>
            <FormInput placeholder="City" name='receiverCity' help={touched.receiverCity && errors.receiverCity ?errors.receiverCity:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>State</Text>
            <FormInput placeholder="State" name='receiverState' help={touched.receiverState && errors.receiverState ?errors.receiverState:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Postcode</Text>
            <FormInput placeholder="Postcode" name='receiverPostalCode' help={touched.receiverPostalCode && errors.receiverPostalCode ?errors.receiverPostalCode:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-50percent">
            <Text fontSize='m'>Country</Text>
            <FormSelectCountries disabled={true} name='receiverCountry' variant='receiver-country'></FormSelectCountries>
          </FormColumn>
        </FormRow>
      </FormDropDown>
      <FormDropDown title='4. Parcel details'>
        <FormRow>
          <FormColumn className="w-100percent">
            <Text fontSize='m'>Parcel contents</Text>
            <FormInput placeholder="Contents" name='quotedParcelContent' help={touched.quotedParcelContent && errors.quotedParcelContent ?errors.quotedParcelContent:''}></FormInput>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Courier</Text>
            <FormInput disabled={true} placeholder="courier" name='quotedCourierCourier'></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Service</Text>
            <FormInput disabled={true} placeholder="courier" name='quotedCourierService'></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Parcel type</Text>
            <FormSelect disabled={true} variant='packaging' name='quotedParcelPackaging'></FormSelect>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Weight (kg)</Text>
            <FormInput disabled={true} placeholder="Weight" name='quotedParcelWeight' value={values.quotedParcelWeight.toFixed(1)}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Dimension (cm)</Text>
            <FormInput placeholder="Optional" name='quotedParcelDimension' help={touched.quotedParcelDimension && errors.quotedParcelDimension ?errors.quotedParcelDimension:''}></FormInput>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Parcel value (SGD)</Text>
            <FormInput variant='number' placeholder="value" name='quotedParcelValue' help={touched.quotedParcelValue && errors.quotedParcelValue ?errors.quotedParcelValue:''}></FormInput>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn className="w-66percent">
            <Text fontSize='m'> Drop-off date & time</Text>
            <DatePickerWrapper>
              <DatePicker name='quotedParcelDeliveryDate' allowClear={false} value={moment(values.quotedParcelDeliveryDate,'x')} format="DD-MMM-YYYY HH:mm" showTime={{ format: 'HH:mm' }} minuteStep={15} hourStep={1} disabledTime={disabledDateTime} disabledDate={disabledDate} showNow={false} onOk={handleDatePickerOk} />
            </DatePickerWrapper>
          </FormColumn>
          <FormColumn className="w-33percent">
            <Text fontSize='m'>Estimated price (SGD)</Text>
            <FormInput disabled={true} placeholder="Quote" name='quotedCourierPrice' value={values.quotedCourierPrice.toFixed(2)}></FormInput>
            <DiscountCodeText onClick={handleDiscountCode}>{bookingForm.discountCode===''?'Apply discount':'Discount: '+bookingForm.discountCode}</DiscountCodeText>
          </FormColumn>
        </FormRow>

        <FormColumn>
          <Text fontSize='m'>Note:</Text>
          <Text fontSize='m'>1. Price quoted are estimated and subject to changes. Final price will be determined at the outlet.</Text>
          <Text fontSize='m'>2. Weight will be determined by actual or volumetric weight (VW) whichever is higher. Calculate <a href="https://aviationcargo.dhl.com/business-tools/volume-calculator" target="_blank" rel="noopener noreferrer">VW</a>.</Text>
          <Text fontSize='m'>3. To edit the Weight and Parcel type, please return to Step 1 as quoted price will be affected.</Text>
          <Text fontSize='m'>4. To change Courier, please return to Step 2.</Text>
          <Text fontSize='m'>5. For domestic delivery, additional charges will be applicable for Secured Zone (Jurong Island/PSA ports/ Changi Airfreight Centre & Airport/ SAF & Home Team Facilities)</Text>
        </FormColumn>
      </FormDropDown>
    </form>
  );
};

interface IFormProps {
  setPage:React.Dispatch<React.SetStateAction<number>>;
  setModalVisible:React.Dispatch<React.SetStateAction<boolean>>;
  bookingForm: IBooking;
  setBookingForm: React.Dispatch<React.SetStateAction<IBooking>>;
}

const BookingForm = withFormik<IFormProps,IInnerFormValues>({
  validationSchema:validationSchema,  
  validateOnChange:false,

  // Transform outer props into form values
  mapPropsToValues: props => {
    return {
      tgoOutlet: props.bookingForm.tgoOutlet||'',
      senderName: props.bookingForm.sender.name||'',
      senderCompany: props.bookingForm.sender.company||'',
      senderEmail: props.bookingForm.sender.email||'',
      senderContactNo: props.bookingForm.sender.contactNo||'',
      senderAddress: props.bookingForm.sender.address||'',
      senderSuburb: props.bookingForm.sender.suburb||'',
      senderCity: props.bookingForm.sender.city||'',
      senderState: props.bookingForm.sender.state||'',
      senderPostalCode: props.bookingForm.sender.postalCode||'',
      senderCountry: props.bookingForm.sender.country||'',

      receiverName: props.bookingForm.receiver.name||'',
      receiverCompany: props.bookingForm.receiver.company||'',
      receiverEmail: props.bookingForm.receiver.email||'',
      receiverContactNo: props.bookingForm.receiver.contactNo||'',
      receiverAddress: props.bookingForm.receiver.address||'',
      receiverSuburb: props.bookingForm.receiver.suburb||'',
      receiverCity: props.bookingForm.receiver.city||'',
      receiverState: props.bookingForm.receiver.state||'',
      receiverPostalCode: props.bookingForm.receiver.postalCode||'',
      receiverCountry: props.bookingForm.receiver.country||'',

      quotedParcelContent: props.bookingForm.quotedParcel.content||'',
      quotedParcelDeliveryDate:(props.bookingForm.quotedParcel.deliveryDate===0)?getCurrentTimeUnix():props.bookingForm.quotedParcel.deliveryDate,
      quotedParcelValue:props.bookingForm.quotedParcel.value||0.00,
      quotedParcelPackaging:props.bookingForm.quotedParcel.packaging||'',
      quotedParcelDimension:props.bookingForm.quotedParcel.dimension||'',
      quotedParcelWeight:props.bookingForm.quotedParcel.weight||0.0,

      quotedCourierCourier: props.bookingForm.quotedCourier.courier||'',
      quotedCourierEta: props.bookingForm.quotedCourier.eta||'',
      quotedCourierPrice: props.bookingForm.quotedCourier.price||0.00,
      quotedCourierService: props.bookingForm.quotedCourier.service||'',
      quotedCourierServiceInfo: props.bookingForm.quotedCourier.serviceInfo||'',
    };
  },
  // Form Submission
  handleSubmit: (values, formikBag) => {
    console.log('Submitting Booking Form',values);
    scrollUpWindow();
    // first setBookingForm on ValidationSuccess
    // second setPage
    formikBag.props.setBookingForm(prevState => {
      return { 
        ...prevState, 
        sender:{
          ...prevState.sender,
          name:values.senderName,
          company:values.senderCompany,
          email:values.senderEmail,
          contactNo:values.senderContactNo,
          address:values.senderAddress,
          suburb:values.senderSuburb,
          city:values.senderCity,
          state:values.senderState,
          postalCode:values.senderPostalCode,
          country:values.senderCountry
        },
        receiver:{
          ...prevState.receiver,
          name:values.receiverName,
          company:values.receiverCompany,
          email:values.receiverEmail,
          contactNo:values.receiverContactNo,
          address:values.receiverAddress,
          suburb:values.receiverSuburb,
          city:values.receiverCity,
          state:values.receiverState,
          postalCode:values.receiverPostalCode,
          country:values.receiverCountry
        },
        quotedParcel:{
          ...prevState.quotedParcel,
          content:values.quotedParcelContent,
          dimension:values.quotedParcelDimension,
          value:values.quotedParcelValue,
          deliveryDate:values.quotedParcelDeliveryDate
        }
      };
    });
    formikBag.props.setPage(5);
  },

})(innerFormPg4);

const Page4:React.FC<{
  setPage:React.Dispatch<React.SetStateAction<number>>,
  // Courier & Parcel
  bookingForm:IBooking,
  setBookingForm:React.Dispatch<React.SetStateAction<IBooking>>,
}>= (props) => {
  const [isModalVisible,setModalVisible]=useState(false);

  return(
    <Wrapper>
      <BookingForm setPage={props.setPage} bookingForm={props.bookingForm} setBookingForm={props.setBookingForm } setModalVisible={setModalVisible}/>
      <ModalDiscountCode setBookingForm={props.setBookingForm} isModalVisible={isModalVisible} setIsModalVisible={setModalVisible}></ModalDiscountCode>
    </Wrapper>
  );
};

export default Page4;
import axios from 'axios';
import { IUserReq, IUserRes} from './api.interface';
import { AxiosResponse } from 'axios';

const apiBaseURL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

export const postStaffLogIn= async (req: IUserReq):Promise<AxiosResponse<IUserRes> | boolean> =>{
  //Actual Response
  const postUserJson=req;
  const url= `${apiBaseURL}/staff/auth/sign-in`;
  return axios.post<IUserRes>(url,postUserJson)
    .then(response => {
      console.log(response);
      return response;
    })
    .catch((error)=> {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('Failed: Server responded with error message', error.response);
        return false;
      } else {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        console.log('Failed: No Responses from server', error.request);
        return false;
      }
    });
};
/* eslint-disable react/no-unescaped-entities */
import React,{useState} from 'react';
import { Wrapper } from './styles';
import {IBooking} from 'pages/staff-portal/booking/api/schema.interface';
import { BookingForm } from './BookingForm';
import { BookingFormReadOnly } from './BookingFormReadOnly';
import { PARCEL_STATUS } from 'common/utils/constants/parcel-status.constants';
import { Modal } from 'common/components/Modal';

export const BookingDetail:React.FC<{
  // Courier & Parcel
  booking:IBooking,
  setBooking:React.Dispatch<React.SetStateAction<IBooking>>,
  
}>= (props) => {
  const [isModalVisible,setModalVisible]=useState(false);
  const [modalVariant,setModalVariant]=useState<'error'|'success'>('error');
  const showModalError=()=>{
    setModalVisible(true);
    setModalVariant('error');
  };
  const showModalSuccess=()=>{
    setModalVisible(true);
    setModalVariant('success');
  };
  return(
    <Wrapper>
      {props.booking.parcelStatus===PARCEL_STATUS.Pending||props.booking.parcelStatus===PARCEL_STATUS.Received?
        (<BookingForm booking={props.booking} setBooking={props.setBooking} showModalSuccess={showModalSuccess} showModalError={showModalError}/>):null
      }
      {props.booking.parcelStatus===PARCEL_STATUS.OutForDelivery||props.booking.parcelStatus===PARCEL_STATUS.Rejected?
        (<BookingFormReadOnly booking={props.booking} setBooking={props.setBooking}></BookingFormReadOnly>):null
      }
      <Modal isModalVisible={isModalVisible} setIsModalVisible={setModalVisible} variant={modalVariant}></Modal>
    </Wrapper>
  );
};
import { IBooking } from 'pages/staff-portal/booking/api/schema.interface';

export const mapPropToValueBookingFormReadOnly=(booking:IBooking)=>{
  return {
    tgoOutlet: booking.tgoOutlet||'',
    senderName: booking.sender.name||'',
    senderCompany: booking.sender.company||'',
    senderEmail: booking.sender.email||'',
    senderContactNo: booking.sender.contactNo||'',
    senderAddress: booking.sender.address||'',
    senderSuburb: booking.sender.suburb||'',
    senderCity: booking.sender.city||'',
    senderState: booking.sender.state||'',
    senderPostalCode: booking.sender.postalCode||'',
    senderCountry: booking.sender.country||'',

    receiverName: booking.receiver.name||'',
    receiverCompany: booking.receiver.company||'',
    receiverEmail: booking.receiver.email||'',
    receiverContactNo: booking.receiver.contactNo||'',
    receiverAddress: booking.receiver.address||'',
    receiverSuburb: booking.receiver.suburb||'',
    receiverCity: booking.receiver.city||'',
    receiverState: booking.receiver.state||'',
    receiverPostalCode: booking.receiver.postalCode||'',
    receiverCountry: booking.receiver.country||'',

    quotedParcelContent: booking.quotedParcel.content||'',
    quotedParcelDeliveryDate:booking.quotedParcel.deliveryDate,
    quotedParcelValue:booking.quotedParcel.value||0,
    quotedParcelPackaging:booking.quotedParcel.packaging||'',
    quotedParcelDimension:booking.quotedParcel.dimension||'',
    quotedParcelWeight:booking.quotedParcel.weight||0,

    quotedCourierCourier: booking.quotedCourier.courier||'',
    quotedCourierEta: booking.quotedCourier.eta||'',
    quotedCourierPrice: booking.quotedCourier.price||0,
    quotedCourierService: booking.quotedCourier.service||'',
    quotedCourierServiceInfo: booking.quotedCourier.serviceInfo||'',

    finalCheckNoDangerousGood:booking.finalCheck.noDangerousGood||false,
    finalCheckParcelWeight:booking.finalCheck.parcelWeight||false,
    finalCheckParcelSize:booking.finalCheck.parcelSize||false,
    finalCheckFragileStickers:booking.finalCheck.fragileStickers||false,
    finalCheckSenderReceiverDetails:booking.finalCheck.senderReceiverDetails||false,
    finalCheckParcelDetails:booking.finalCheck.parcelDetails||false,
    finalCheckRemarks:booking.finalCheck.remarks||'',

    prevParcelStatus:booking.parcelStatus||'',
    parcelStatus:booking.parcelStatus||'',
    awbRef:booking.awbRef||'',
    collectionTimeStamp:booking.collectionTimeStamp||-1,
    collectionRef:booking.collectionRef||'',
    bookingRef:booking.bookingRef||''
  };
};

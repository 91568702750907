import React from 'react';
import { IBooking} from 'pages/customer-portal/parcel-booking/api/schema.interface';
import { withFormik , FormikProps} from 'formik';
import { validationSchema, IInnerFormValues } from './validation';
import Text from 'common/components/Text';
import { FormWrapper} from 'common/components/FormWrapper';
import { Wrapper, Pg1WeightNote,FormColumn,FormRow, FormDiv } from './styles';
import { FormSelect } from 'common/components/FormSelect';
import { FormInput } from 'common/components/FormInput';
import { FormSelectCountries } from 'common/components/FormSelectCountries';

// The type of props MyForm receives
const innerForm= (props: FormikProps<IInnerFormValues>) => { 
  const { touched, errors } = props;
  return(
    <form key="courier-form" onSubmit={props.handleSubmit} id="courier-form">
      {/* Normal Layout */}
      <FormRow>
        <FormColumn>
          <FormDiv className="h-25px">
            <Text fontSize='h5'>From</Text>
          </FormDiv>
          <FormSelectCountries name="senderCountry" placeholder="Country" variant='sender-country'></FormSelectCountries>
        </FormColumn>
        <FormColumn>
          <FormDiv className="h-25px">
            <Text fontSize='h5'>T.GO outlets</Text>
          </FormDiv>
          <FormSelect name="tgoOutlet" placeholder="T.GO Outlet" variant='tgo-outlet'/>
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn className="w-50percent">
          <FormDiv className="h-25px">
            <Text fontSize='h5'>To</Text>
          </FormDiv>
          <FormSelectCountries name="receiverCountry" placeholder="Country" variant='receiver-country'></FormSelectCountries>
        </FormColumn>
        <FormColumn className="w-50percent">
          <FormDiv className="h-25px">
            <Text fontSize='h5'>Postcode</Text>
          </FormDiv>
          <FormInput placeholder="Postcode" name="receiverPostalCode" help={touched.receiverPostalCode && errors.receiverPostalCode ?errors.receiverPostalCode:''} />
        </FormColumn>
      </FormRow>
      <FormRow className='formParcelWeight'>
        <FormColumn className="w-50percent">
          <FormDiv className="h-25px">
            <Text fontSize='h5'>Weight (kg)</Text>
          </FormDiv>
          <FormInput variant="number" name="quotedParcelWeight" placeholder="kg" help={touched.quotedParcelWeight && errors.quotedParcelWeight ?errors.quotedParcelWeight:''}/>
          <Pg1WeightNote>Weight will be determined by actual or volumetric weight (VW), whichever higher. Calculate the volmetric weight 
            <a href="https://aviationcargo.dhl.com/business-tools/volume-calculator" target="_blank" rel="noopener noreferrer">here</a>
          </Pg1WeightNote>
        </FormColumn>
        <FormColumn className="w-50percent">
          <FormDiv className="h-25px">
            <Text fontSize='h5'>Type</Text>
          </FormDiv>
          <FormSelect placeholder="Packaging" name="quotedParcelPackaging" variant='packaging'/>
        </FormColumn>
      </FormRow>
    </form>
  );
};

interface IFormProps {
  setPage:React.Dispatch<React.SetStateAction<number>>;
  bookingForm: IBooking;
  setBookingForm: React.Dispatch<React.SetStateAction<IBooking>>;
}

const CourierForm = withFormik<IFormProps,IInnerFormValues>({
  validationSchema:validationSchema,  
  validateOnChange:false,

  // Transform outer props into form values
  mapPropsToValues: props => {
    return {
      senderCountry: props.bookingForm.sender.country||'',
      tgoOutlet:props.bookingForm.tgoOutlet||'',
      receiverCountry: props.bookingForm.receiver.country||'',
      receiverPostalCode:props.bookingForm.receiver.postalCode||'',
      quotedParcelWeight:props.bookingForm.quotedParcel.weight||0,
      quotedParcelPackaging:props.bookingForm.quotedParcel.packaging||''
    };
  },

  // Form Submission
  handleSubmit: (values, formikBag) => {
    console.log('Submitting',values);
    // first setBookingForm on ValidationSuccess
    // second setPage
    formikBag.props.setBookingForm(prevState => {
      return { 
        ...prevState, 
        sender:{
          ...prevState.sender,
          country:values.senderCountry
        },
        receiver:{
          ...prevState.receiver,
          country:values.receiverCountry,
          postalCode:values.receiverPostalCode
        },
        quotedParcel:{
          ...prevState.quotedParcel,
          weight:values.quotedParcelWeight,
          packaging:values.quotedParcelPackaging
        }
      };
    });
    formikBag.props.setPage(2);
  },

})(innerForm);

const Page1:React.FC<{
    // Page transition
    setPage:React.Dispatch<React.SetStateAction<number>>,
    // Courier & Parcel
    bookingForm:IBooking,
    setBookingForm:React.Dispatch<React.SetStateAction<IBooking>>,
}>= (props) => {
  return(
    <Wrapper>
      <FormWrapper>
        <CourierForm setPage={props.setPage} bookingForm={props.bookingForm} setBookingForm={props.setBookingForm}></CourierForm>
      </FormWrapper>
    </Wrapper>
  );
};

export default Page1;
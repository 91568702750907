import styled from 'styled-components';
import { NavLink as LinkReactRouter } from 'react-router-dom';

const Wrapper= styled.div`
  display:flex;
  flex-direction: column;

  @media (min-width:1025px){
    display:none;
  }
`;
const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  &.active{
  }
  &.inactive{
    display: none;
  }
`;
const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
const Li = styled.li`
  background-color: black;
  &:hover{
    background-color: white;
    color:black;
  }
`;
const A = styled.a`
  display: flex;
  justify-content: center;
  padding:14px 16px;
  width: 100%;
  font-family: SourceSansPro;
  font-size: 18px;
  color: #fff;
  &:hover{
    background-color: white;
    color:black;
  }
`;
const Link = styled(LinkReactRouter)`
  display: flex;
  justify-content: center;
  padding:14px 16px;
  width: 100%;
  font-family: SourceSansPro;
  font-size: 18px;
  color: #fff;
  &:hover{
    background-color: white;
    color:black;
  }
  &.active{
    color:#3bade3;
  }
`;


export { Wrapper,Nav,Ul,Li,A, Link};
import { Action } from '../action-creator/action';
import { ActionType } from 'state/action-creator/action-types';
import { IUser } from 'pages/staff-portal/authentication/api/schema.interface';

export interface UserState{
  user?:IUser;
  accessToken?:string;
}
// in store.ts, if persistedState is undefined. initialState loaded
const initialState:UserState = {};

const reducer = (state: UserState = initialState, action: Action): UserState => {
  switch (action.type){
  case ActionType.LOGIN:
    console.log('Dispatched Login: ',state, action, action.payload);
    // Reducers stores JWT Token & User
    state={
      user:action.payload.user,
      accessToken:action.payload.accessToken
    };
    return state;
  case ActionType.SIGNOUT:
    console.log('Dispatched SignOut',state, action);
    state={};
    return state;
  default:
    return state;
  }
};

export default reducer;
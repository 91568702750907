import React from 'react';
import { Wrapper ,LogDiv } from './styles';

const BookingLogs:React.FC = () => {  
  return(
    <Wrapper>
      <LogDiv>10/09/2021 - 18:20:29 [admin] Status update: Out for delivery </LogDiv>
      <LogDiv>10/09/2021 - 18:20:29 [admin] Status: Rejected </LogDiv>
      <LogDiv>08/09/2021 - 18:20:29 [Jerry] Status: Received parcel  </LogDiv>
    </Wrapper>
  );
};
export default BookingLogs;

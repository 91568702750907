import React, { useEffect, useRef } from 'react';
import { Wrapper, Header1, Form ,Row , ForgetPassword} from './styles';
import Button from 'common/components/Button';
import { withFormik , FormikProps} from 'formik';
import { validationSchema, IInnerFormValues } from './validation';
import { FormInput } from 'common/components/FormInput';
import { FormInputPassword } from 'common/components/FormInputPassword';
import { postStaffLogIn } from '../api/api';
import { IUserRes } from '../api/api.interface';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/index';
import { AxiosResponse } from 'axios';
import { useSelector} from 'react-redux';
import { RootState } from 'state/reducers/index';
import {isEmpty} from 'lodash';
import { IUser } from '../api/schema.interface';
import { Dispatch,Action } from 'redux';

// The type of props MyForm receives
const innerForm= (props: FormikProps<IInnerFormValues>) => { 
  const { touched, errors } = props;
  return(
    <Form key="login-form" onSubmit={props.handleSubmit} id="login-form">
      <Header1>Log In</Header1>
      <FormInput variant="string" name="email" placeholder="Email" help={touched.email && errors.email ?errors.email:''}/>
      <FormInputPassword name="password" placeholder="Password" help={touched.password && errors.password ?errors.password:''}/>
      <Row>
        <ForgetPassword>Forgot your password? </ForgetPassword>
        <Button variant="primary" form='login-form' type='submit'>Log In</Button>
      </Row>
    </Form>
  );
};

interface IFormProps {
  userLogInRedux:(user: IUser, accessToken: string) => (dispatch: Dispatch<Action>) => void;
}

const LogInForm = withFormik<IFormProps,IInnerFormValues>({
  validationSchema:validationSchema,  
  validateOnChange:false,

  // Transform outer props into form values
  mapPropsToValues: () => {
    return {
      email:'',
      password:''
    };
  },

  // Form Submission
  handleSubmit: async (values, formikBag) => {
    console.log('Submitting',values);
    
    // axios call
    const userRes:AxiosResponse<IUserRes>|boolean= await postStaffLogIn({email:values.email,password:values.password});
    // Handle Errors
    if(!userRes&&typeof(userRes)!='object'){
      formikBag.setErrors({password:'wrong username & password'});
    }
    // redux: action creator to dispatch a login action
    if(typeof(userRes)==='object'){
      formikBag.props.userLogInRedux(userRes.data.user,userRes.data.accessToken);
    }
  },
})(innerForm);

const LogIn:React.FC = () => {  
  // Redux: ActionCreator Dispatcher
  const dispatchRedux = useDispatch();
  const { userLogInRedux, userSignOutRedux } = bindActionCreators(actionCreators, dispatchRedux);
  // Redux: Subcribe to user Store
  const userStateRedux = useSelector((state: RootState) => state.userState);
  // Router: useHistory redirection
  const history = useHistory();

  useEffect(()=>{
    // 1. Initial Load: Logout exisiting user
    userSignOutRedux();
  },[]);
  
  const initiaLoad = useRef(true);
  useEffect(() => {
    // 2. Subsequent userStateRedux change: Check if Authenticated, push to /staff/
    if(!isEmpty(userStateRedux)&&!initiaLoad.current){
      history.push('/staff/');
    }
    initiaLoad.current=false;
  }, [userStateRedux]);

  return(
    <Wrapper>
      <LogInForm userLogInRedux={userLogInRedux} ></LogInForm>
    </Wrapper>
  );
};
export { LogIn };
 
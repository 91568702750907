import { IUser } from 'pages/staff-portal/authentication/api/schema.interface';
import { Dispatch } from 'redux';
import { ActionType } from 'state/action-creator/action-types';
import { Action } from './action';

// A Servant that dispatches (action) for the king
// an action creator is a function that creates and dispatches an action for the View

export const userLogInRedux = (user:IUser, accessToken:string):( (dispatch: Dispatch<Action>)=>void ) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.LOGIN, 
      payload: {
        'user':user,
        'accessToken':accessToken
      }
    });
  };
};

export const userSignOutRedux = ():( (dispatch: Dispatch<Action>)=>void ) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SIGNOUT, 
    });
  };
};
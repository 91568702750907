import styled from 'styled-components';
// 320px — 480px: Mobile devices.
// 481px — 768px: iPads, Tablets.
// 769px — 1024px: Small screens, laptops.
// 1025px — 1200px: Desktops, large screens.
// 1201px and more — Extra large screens, TV.
const HeaderWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-right:auto;
  background-color: #010101;
  width:100%;
  max-width:1200px;
  height: 145px;
`;

const OuterHeaderWrapper = styled.div`
  background-color: #010101;
  width:100%;
  display:flex;
`;

const Logo = styled.img`
  padding-top:50px;
  width: 100px;
  text-align: center;
  color:white;
`;
export { OuterHeaderWrapper,HeaderWrapper, Logo };
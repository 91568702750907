import styled from 'styled-components';

const Wrapper = styled.div`
    background-color: #47b9ef;
    min-height:calc((100vh - 145px));;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Backlink = styled.div`
    width:500px;
    margin-top:100px;

    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
`;

const ForgotPasswordWrapper = styled.div`
    width:500px;
    height:400px;
    padding:30px 50px 50px 50px;
    margin:10px 0px 30px 0px;
    border-radius: 10px;
    background-color: #fff;
`;

const Header1 = styled.div`
    font-family: HelveticaNeue;
    font-size: 48px;
    margin: 10px 0px;
`;

const BodyText = styled.div`
    font-family: Helvetica;
    font-size: 18px;
    margin: 10px 0px;
    color:#535353;
`;

const AntdInputEmail  = styled.div`
    margin: 10px 0px;
    & input.ant-input {
        font-size: 20px;
        color:#535353;
    }
`;

const AntdButtonForgotPassword = styled.a`
    width:50%;
    & .ant-btn-link{
    }
`;

const AntdButtonSubmit  = styled.div`
    margin-left:auto;
    & button.ant-btn{
        margin-left: auto;
    }
`;

const Row = styled.div`
    display:flex;
    flex-direction:row;
    margin-top:50px;
`;


export { Wrapper, Backlink, ForgotPasswordWrapper, Header1, BodyText, AntdInputEmail ,AntdButtonForgotPassword, AntdButtonSubmit ,Row };
import React from 'react';
import { Wrapper } from './styles';

const MemberBooking:React.FC = () => {  
  return(
    <Wrapper>
            Member Bookings
    </Wrapper>
  );
};
export default MemberBooking;
